import Image from "next/image";

import { Community } from "../../assets-protocol-v4/animations/Community";
import { FizNode } from "../../assets-protocol-v4/animations/FizNode";
import { Matchmaking } from "../../assets-protocol-v4/animations/Matchmaking";
import { Trust } from "../../assets-protocol-v4/animations/Trust";
import ProviderImage from "../../assets-protocol-v4/images/compute-marketplace-section/provider.webp";
import TierImage from "../../assets-protocol-v4/images/compute-marketplace-section/tiers.webp";
import { trackUserEvent } from "../../utils/analytics";
import { RedirectLinks } from "../../utils/common";
import { VerticalCard } from "../Card";
import SecondaryButton from "../SecondaryButton";
import SectionHeading from "../SectionHeading";
import SectionSubtext from "../SectionSubtext";

const cardConfig = [
  {
    id: 1,
    title: "INTELLIGENT MATCHMAKING",
    text: "Effortlessly pairs user requests with optimal compute providers",
    image: <Matchmaking />,
  },
  {
    id: 2,
    title: "TIERED PROVIDER SYSTEM",
    text: "Categorizes providers based on performance and trust",
    image: <Image src={TierImage} alt="" className="grayscale" />,
  },
  {
    id: 3,
    title: "DECENTRALIZED TRUST",
    text: "Ensures network security and trust through decentralized validation.",
    image: <Trust />,
  },
  {
    id: 4,
    title: "COMMUNITY POWERED & GOVERNED",
    text: "Empowers users through decentralized governance.",
    image: <Community />,
  },
  {
    id: 5,
    title: "FIZZ NODE",
    text: "Tap into, or contribute to, a global supply of community-supplied compute",
    image: <FizNode />,
  },
  {
    id: 6,
    title: "PROVIDER",
    text: "Maximize your returns as an enterprise GPU provider",
    image: <Image src={ProviderImage} alt="" className="grayscale" />,
  },
];

const ComputeMarketplaceSection = () => {
  return (
    <section>
      <hr className="hidden mb-[100px] lg:flex h-[1px] w-full bg-protocolNew-border" />
      <div className="w-full px-6 lg:px-sides text-sans flex flex-col gap-10 lg:gap-16">
        <div className="flex flex-col lg:flex-row lg:justify-between lg:items-end gap-12 lg:gap-0">
          <div className="flex flex-col gap-2">
            <SectionSubtext variant="light">
              THE WORLD&apos;S LEADING DEPIN COMPUTE MARKET
            </SectionSubtext>
            <SectionHeading>
              <div>FUTURE-PROOF YOUR BUSINESS</div>{" "}
              <div>VIA SPHERON&apos;S DECENTRALIZED </div>
              <div>COMPUTE NETWORK</div>
            </SectionHeading>
          </div>
          <div
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-delay="100"
            data-aos-duration="600"
            className="lg:basis-44 shrink-0 w-44 h-[52px]"
          >
            <SecondaryButton
              variant="dark"
              onClick={() => {
                trackUserEvent("Get Started Click");
                window.open(RedirectLinks.CONSOLE_APP, "_blank");
              }}
            >
              GET STARTED
            </SecondaryButton>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-4 px-0 lg:px-2">
          {cardConfig.map((config) => {
            return (
              <VerticalCard
                className="h-[413px] min-h-[413px] [@media(min-width:3000px)]:h-[12vw] lg:h-[413px] md:h-[840px] sm:h-[720px] relative justify-end overflow-hidden"
                key={config.id}
                image={config.image}
                title={config.title}
                text={config.text}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ComputeMarketplaceSection;

import Image from "next/image";

import DecentralizedImage from "../../assets-protocol-v4/images/hero-section/decentralized.svg";
import { trackUserEvent } from "../../utils/analytics";
import { RedirectLinks } from "../../utils/common";
import SecondaryButton from "../SecondaryButton/SecondaryButton";

const ButtonConfigs = [
  {
    id: 1,
    text: "CONNECT YOUR SERVERS",
    eventText: "Connect Your Servers",
    subtext: "For Miners & Data Centers",
    href: RedirectLinks.PROVIDER_APP,
    className: "order-3 md:order-[0] basis-full md:basis-auto",
  },
  {
    id: 2,
    text: "SHARE YOUR GPU",
    eventText: "Share Your Gpu",
    subtext: "For Laptop & Desktop Owners",
    href: RedirectLinks.FIZZ_NODE_APP,
    className: "",
  },
  {
    id: 3,
    text: "LEASE GPU POWER",
    eventText: "Lease Gpu Power",
    subtext: "For Developers",
    href: RedirectLinks.CONSOLE_APP,
    className: "col-span-2",
  },
];

const CardConfig = [
  {
    id: 1,
    title: "TOTAL TFLOPS",
    value: "58,000",
    duration: "from last week",
  },
  {
    id: 2,
    title: "TOTAL LEASED",
    value: "32,000",
    duration: "from last week",
  },
  {
    id: 3,
    title: "TOTAL PAYOUT",
    value: "500K",
    duration: "from last week",
  },
];

const HeroSection = () => {
  return (
    <section className="relative border-b border-protocolNew-border rounded-b-md">
      <div className="absolute lg:mx-sides hidden lg:flex justify-center border-x lg:w-content h-full z-[-1]">
        <div className="w-1/3 border-x"></div>
      </div>
      <div className="absolute mx-6 lg:hidden flex justify-center border-x w-content-mobile h-full z-[-1]">
        <div className="w-1/3 border-x"></div>
      </div>
      <div className="[animation-delay:1000ms] animate-landing-blur opacity-0 absolute left-6 right-6 lg:left-sides lg:right-sides w-content-mobile lg:w-content lg:pt-36 pt-24">
        <div className="min-w-full text-center">
          <Image
            src={DecentralizedImage}
            alt=""
            className="min-w-full w-full h-auto"
          />
        </div>
      </div>
      <h1 className="hidden lg:block [animation-delay:1000ms] animate-landing-blur opacity-0 px-6 lg:px-sides text-left font-heading text-[12vw] lg:text-[5.4vw] leading-[84%] lg:leading-[83.4%] max-w-full pt-28 lg:pt-48">
        <div>GPU MARKETPLACE TO</div> <div> LEASE, SELL & INNOVATE</div>
      </h1>
      <h1 className="lg:hidden [animation-delay:1000ms] animate-landing-blur opacity-0 px-6 lg:px-sides text-left font-heading text-[9.2vw] lg:text-[5.4vw] leading-[100%] lg:leading-[83.4%] max-w-full pt-28 lg:pt-48">
        <div>GPU MARKETPLACE</div> <div>TO LEASE, SELL & </div>
        <div>INNOVATE</div>
      </h1>
      <p className="[animation-delay:1300ms] w-[95%] font-inter opacity-0 animate-landing-blur px-6 lg:px-sides text-left text-base leading-6 mb-[60px] mt-11">
        Join our peer-to-peer network where anyone—from data centers to
        individual device owners—can monetize their GPU power. Startups and
        developers can access affordable, scalable GPU resources to accelerate
        their projects. Connect now to power and be powered by the future of
        computing
      </p>
      <div className="[animation-delay:1500ms] animate-landing-blur opacity-0 mx-6 mb-[72px] flex-wrap lg:ml-sides hidden md:flex justify-between md:justify-start gap-10">
        {ButtonConfigs.map((button) => {
          return (
            <div
              key={button.id}
              className={"flex flex-col " + button.className}
            >
              <SecondaryButton
                onClick={() => {
                  trackUserEvent(button.eventText + " Click");
                  window.open(button.href, "_blank");
                }}
                className="px-8 py-4"
                variant="dark"
              >
                {button.text}
              </SecondaryButton>
              <span className="font-inter text-[13px] leading-6 text-[#5C5C66]">
                {button.subtext}
              </span>
            </div>
          );
        })}
      </div>
      <div className="[animation-delay:1500ms] animate-landing-blur opacity-0 flex flex-col md:hidden gap-5">
        <div className="mx-6 flex justify-between">
          <div className="flex flex-col">
            <SecondaryButton
              onClick={() => {
                trackUserEvent(ButtonConfigs[1].eventText + " Click");
                window.open(ButtonConfigs[1].href, "_blank");
              }}
              className="!text-[13px] !w-36 py-3"
              variant="dark"
            >
              {ButtonConfigs[1].text}
            </SecondaryButton>
            <span className="font-inter text-[10px] text-center leading-6 text-[#5C5C66]">
              {ButtonConfigs[1].subtext}
            </span>
          </div>
          <div className="flex flex-col">
            <SecondaryButton
              onClick={() => {
                trackUserEvent(ButtonConfigs[2].eventText + " Click");
                window.open(ButtonConfigs[2].href, "_blank");
              }}
              className="!text-[13px] !w-36 py-3"
              variant="dark"
            >
              {ButtonConfigs[2].text}
            </SecondaryButton>
            <span className="font-inter text-center text-[10px] leading-6 text-[#5C5C66]">
              {ButtonConfigs[2].subtext}
            </span>
          </div>
        </div>
        <div className="w-full px-6 flex flex-col mb-8">
          <SecondaryButton
            onClick={() => {
              trackUserEvent(ButtonConfigs[0].eventText + " Click");
              window.open(ButtonConfigs[0].href, "_blank");
            }}
            className="!text-[13px]  py-3"
            variant="dark"
          >
            {ButtonConfigs[0].text}
          </SecondaryButton>
          <span className="font-inter text-center text-[10px] leading-6 text-[#5C5C66]">
            {ButtonConfigs[0].subtext}
          </span>
        </div>

        {/* {ButtonConfigs.map((button) => {
          return (
            <div
              key={button.id}
              className={"flex flex-col " + button.className}
            >
              <SecondaryButton
                onClick={() => window.open(button.href, "_blank")}
                className="!text-[13px] py-3"
                variant="dark"
              >
                {button.text}
              </SecondaryButton>
              <span className="font-inter text-[10px] leading-6 text-[#5C5C66]">
                {button.subtext}
              </span>
            </div>
          );
        })} */}
      </div>
      <div className="[animation-delay:1500ms] animate-landing-blur opacity-0 mb-40 flex flex-col md:flex-row gap-5 mx-6 flex-wrap lg:ml-sides">
        {CardConfig.map((card) => {
          return (
            <div
              key={card.id}
              className="p-3 min-w-[282px] rounded-lg bg-white border border-protocolNew-border flex flex-col gap-2"
            >
              <span className="font-sans text-protocolNew-base leading-4">
                {card.title}
              </span>
              <div className="flex justify-between items-center font-inter">
                <span className="text-xxxl">{card.value}</span>
                <span className="flex flex-col gap-0 text-[10px] text-right p-1 self-end">
                  <span className="p-1 text-[#5C5C66]">{card.duration}</span>
                </span>
              </div>
            </div>
          );
        })}
      </div>
      {/* <div className="h-[88px] lg:h-11 border-t w-full">
        <div className="relative lg:mx-sides w-content-mobile lg:w-content h-full flex lg:flex-row flex-col">
          <div className="mx-6 lg:mx-0 h-full w-full">
            <PrimaryButton
              className="w-1/3 border-x lg:border-r-0"
              variant="light"
              onClick={() => {
                window.open(
                  "https://docs.spheron.network/user-guide/deploy-your-app",
                  "_blank"
                );
              }}
            >
              Rent GPU Now
            </PrimaryButton>
          </div>
          <div className="lg:w-1/3 shrink-0 border-b lg:border-b-0 w-[calc(100vw-20px)]"></div>
          <div className="mx-6 lg:mx-0 h-full w-full">
            <PrimaryButton
              className="w-1/3 border-x lg:border-l-0"
              variant="light"
              onClick={() => {
                window.open("https://docs.spheron.network/providers", "_blank");
              }}
            >
              Turn your idle GPU into profit
            </PrimaryButton>
          </div>
          <Dot className="top-0 left-0 -translate-x-[3.5px] -translate-y-1 ml-6 lg:ml-0" />
          <Dot className="bottom-0 left-0 -translate-x-[3.5px] translate-y-1 ml-6 lg:ml-0" />
          <Dot className="lg:hidden top-1/2 left-0 -translate-x-[3.5px] -translate-y-[3px] ml-6 lg:ml-0" />
          <Dot className="lg:hidden translate-x-[3.5px] lg:translate-x-[9.5px] top-1/2 -right-6 lg:right-0 -translate-y-[3px]" />
          <Dot className="translate-x-[3.5px] lg:translate-x-[3.5px] top-0 -right-6 lg:right-0 -translate-y-1" />
          <Dot className="translate-x-[3.5px] lg:translate-x-[3.5px] bottom-0 -right-6 lg:right-0 translate-y-1" />
        </div>
      </div> */}
    </section>
  );
};

export default HeroSection;

import { ComponentProps } from "react";

interface SectionHeadingProps extends ComponentProps<"h2"> {
  variant: "dark" | "light";
}

const getSubtextClassNames = (variant: SectionHeadingProps["variant"]) => {
  switch (variant) {
    case "dark":
      return "text-protocolNew-sub-text";
    case "light":
      return "text-protocolNew-base opacity-50";
  }
};

const SectionSubtext = ({
  className,
  children,
  variant,
  ...props
}: SectionHeadingProps) => {
  return (
    <div
      data-aos="fade-down"
      data-aos-once="true"
      data-aos-delay="0"
      data-aos-duration="600"
      className={`text-sm leading-4 font-sans ${getSubtextClassNames(
        variant
      )} ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};

export default SectionSubtext;

import Image from "next/image";

import ZeroGLogo from "../../assets-protocol-v4/images/ecosystem-partners/0g.svg";
import AkaveLogo from "../../assets-protocol-v4/images/ecosystem-partners/akave.svg";
import DenetLogo from "../../assets-protocol-v4/images/ecosystem-partners/denet.svg";
import ExabitsLogo from "../../assets-protocol-v4/images/ecosystem-partners/exabits.svg";
import HeuristLogo from "../../assets-protocol-v4/images/ecosystem-partners/heurist.svg";
import KaisarLogo from "../../assets-protocol-v4/images/ecosystem-partners/kaisar.svg";
import LilypadsLogo from "../../assets-protocol-v4/images/ecosystem-partners/lilypads.svg";
import NeuromeshLogo from "../../assets-protocol-v4/images/ecosystem-partners/neuromesh.svg";
import ParasailLogo from "../../assets-protocol-v4/images/ecosystem-partners/parasail.svg";
import SupersightLogo from "../../assets-protocol-v4/images/ecosystem-partners/supersight.svg";
import SwanchainLogo from "../../assets-protocol-v4/images/ecosystem-partners/swanchain.svg";

// Repeated for the infinite scroll effect
const spheronPartners = [
  { id: 1, icon: ZeroGLogo, altText: "zerog" },
  { id: 2, icon: AkaveLogo, altText: "akave" },
  { id: 3, icon: DenetLogo, altText: "denet" },
  { id: 4, icon: ExabitsLogo, altText: "exabits" },
  { id: 5, icon: HeuristLogo, altText: "heurist" },
  { id: 6, icon: KaisarLogo, altText: "kaisar" },
  { id: 7, icon: LilypadsLogo, altText: "lilypads" },
  { id: 8, icon: NeuromeshLogo, altText: "neuromesh" },
  { id: 9, icon: ParasailLogo, altText: "parasail" },
  { id: 10, icon: SupersightLogo, altText: "supersight" },
  { id: 11, icon: SwanchainLogo, altText: "swanchain" },

  { id: 12, icon: ZeroGLogo, altText: "zerog" },
  { id: 13, icon: AkaveLogo, altText: "akave" },
  { id: 14, icon: DenetLogo, altText: "denet" },
  { id: 15, icon: ExabitsLogo, altText: "exabits" },
  { id: 16, icon: HeuristLogo, altText: "heurist" },
  { id: 17, icon: KaisarLogo, altText: "kaisar" },
  { id: 18, icon: LilypadsLogo, altText: "lilypads" },
  { id: 19, icon: NeuromeshLogo, altText: "neuromesh" },
  { id: 20, icon: ParasailLogo, altText: "parasail" },
  { id: 21, icon: SupersightLogo, altText: "supersight" },
  { id: 22, icon: SwanchainLogo, altText: "swanchain" },
];

const Partners = () => {
  return (
    <div className="relative mx-6 lg:mx-sides overflow-hidden w-content-mobile lg:w-content text-center tracking-[1px] h-24 flex flex-col gap-6 mt-16 mb-10 lg:my-[103px] text-lg leading-5">
      <h3>SPHERON ECOSYSTEM PARTNERS</h3>
      <div className="flex items-center h-[50px] justify-start w-[6000px] animate-marquee">
        {spheronPartners.map((partner) => {
          return (
            <div
              key={partner.id}
              className={`px-10 border-r border-[#878787] last:border-r-0 h-full flex justify-center`}
            >
              <Image
                className="lg:min-w-[200px] lg:min-h-[60px] min-h-[50px]"
                src={partner.icon}
                alt={partner.altText}
                loading="eager"
              />
            </div>
          );
        })}
      </div>
      <div className="absolute -left-[51px] top-1/4 w-[104px] h-[104px] bg-white blur-lg"></div>
      <div className="absolute -right-[51px] top-1/4 w-[104px] h-[104px] bg-white blur-lg"></div>
    </div>
  );
};

export default Partners;

import React from "react";

import Image from "next/image";

import BouncingIcon from "../../assets-protocol-v4/images/business-and-developer-section/bouncing.svg";
import CodeIcon from "../../assets-protocol-v4/images/business-and-developer-section/code.svg";
import LowPriceIcon from "../../assets-protocol-v4/images/business-and-developer-section/low-price.svg";
import SpeedtestIcon from "../../assets-protocol-v4/images/business-and-developer-section/speedtest.svg";
import TrophyIcon from "../../assets-protocol-v4/images/business-and-developer-section/trophy.svg";
import { trackUserEvent } from "../../utils/analytics";
import { RedirectLinks } from "../../utils/common";
import Card from "../Card";
import SecondaryButton from "../SecondaryButton";

const cardContents = [
  {
    id: 1,
    icon: CodeIcon,
    alt: "code-icon",
    title: "Intuitive Dev Tools",
    subtitle:
      "Effortlessly access a diverse pool of CPU and GPU resources that meet any computational need.",
  },
  {
    id: 2,
    icon: LowPriceIcon,
    alt: "low-price-icon",
    title: "Cost-effective Solutions",
    subtitle:
      "Enjoy transparent pricing and efficient resource allocation, powered by Spheron’s Intelligent Matchmaker.",
  },
  {
    id: 3,
    icon: BouncingIcon,
    alt: "bounce-icon",
    title: "Granular Control & Flexibility",
    subtitle:
      "Tailor your deployments to perfectly align with your specific needs, offering detailed control and customization at every level.",
  },
  {
    id: 4,
    icon: SpeedtestIcon,
    alt: "ookla-speedtest-icon",
    title: "Enhanced Performance & Uptime",
    subtitle:
      "Spheron's Slark Nodes guarantee exceptional uptime and consistent performance",
  },
  {
    id: 5,
    icon: TrophyIcon,
    alt: "trophy-icon",
    title: "Rewards For Each Deployment",
    subtitle:
      "Earn rewards with every deployment, as your activity on Spheron becomes a flywheel for success.",
  },
];

const BusinessAndDeveloperSection = () => {
  return (
    <div className="relative bg-protocolNew-base flex flex-col gap-7 lg:gap-y-20">
      <div className="absolute lg:mx-sides hidden lg:flex justify-center border-x lg:w-content h-full z-[1] border-white border-opacity-10">
        <div className="w-1/3 border-x border-white border-opacity-10" />
      </div>
      <div className="absolute mx-6 lg:hidden flex justify-center border-x w-content-mobile h-full z-[1] border-white border-opacity-10">
        <div className="w-1/3 border-x border-white border-opacity-10" />
      </div>
      <div className="flex flex-col items-center mx-6 lg:mx-sides gap-7 lg:gap-8 pt-[72px] lg:pt-[110px] z-[1]">
        <div className="flex flex-col text-center gap-2 font-sans">
          <span
            data-aos="fade-down"
            data-aos-once="true"
            data-aos-delay="0"
            data-aos-duration="600"
            className="text-protocolNew-sub-heading text-[14px] leading-4 tracking-wide uppercase font-normal"
          >
            For Business & Developers
          </span>
          <span
            data-aos="fade-in"
            data-aos-once="true"
            data-aos-delay="100"
            data-aos-duration="600"
            className="mt-2 text-white text-[32px] leading-12 uppercase font-normal"
          >
            In need of GPU power?
          </span>
          <span
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-delay="0"
            data-aos-duration="600"
            className="font-inter text-protocolNew-sub-heading text-base font-normal"
          >
            Access any amount of GPU from anywhere in the world.
          </span>
        </div>
        <div
          data-aos="zoom-in"
          data-aos-once="true"
          data-aos-duration="400"
          data-aos-delay="0"
        >
          <SecondaryButton
            onClick={() => {
              trackUserEvent("Sign Up For Test Credits Click");
              window.open(RedirectLinks.CONSOLE_APP, "_blank");
            }}
            className="px-8 py-4 w-max"
            variant="light"
          >
            SIGN UP FOR TEST CREDITS
          </SecondaryButton>
        </div>
      </div>
      <div className="flex justify-center pb-24 lg:pb-[150px] mx-6 lg:mx-sides">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-11 gap-x-[52px]">
          {cardContents.map((content) => (
            <div
              data-aos="fade-zoom-in"
              data-aos-once="true"
              data-aos-delay="0"
              data-aos-duration="700"
              key={content.id}
              className="col-span-1 flex flex-col gap-3 lg:ml-1"
            >
              <div className="flex items-start">
                <Image
                  src={content.icon}
                  alt={content.alt}
                  height={44}
                  width={44}
                />
              </div>
              <Card.Title className="uppercase">{content.title}</Card.Title>
              <Card.Text>{content.subtitle}</Card.Text>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BusinessAndDeveloperSection;

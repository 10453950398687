import { ReactNode } from "react";

import Card from "./Card";

interface VerticalCardProps {
  image: ReactNode;
  title: string;
  text: string;
  className?: string;
}

const VerticalCard = ({ image, title, text, className }: VerticalCardProps) => {
  return (
    <Card orientation="vertical" className={className}>
      <div
        className="absolute top-4 left-4 right-4 xxxl:left-10 xxxl:right-10 
      min-[2000px]:left-24 min-[2000px]:right-24 
      min-[2500px]:left-[6vw] min-[2500px]:right-[6vw] 
      min-[3000px]:left-[4vw] min-[3000px]:right-[4vw] 
      basis-3/4 shrink-0 flex justify-center"
      >
        {image}
      </div>
      <div className="flex basis-1/4 shrink-0 flex-col gap-2">
        <Card.Title>{title}</Card.Title>
        <Card.Text>{text}</Card.Text>
      </div>
    </Card>
  );
};

export default VerticalCard;

import React, { ComponentProps, PropsWithChildren } from "react";

interface CardProps extends ComponentProps<"div"> {
  orientation: "horizontal" | "vertical";
}

const getCardClassNames = (orientation: CardProps["orientation"]) => {
  switch (orientation) {
    case "horizontal":
      return "items-center justify-between text-left";
    case "vertical":
      return "flex-col text-center";
  }
};

const Card = ({ orientation, className, children, ...props }: CardProps) => {
  return (
    <div
      data-aos="fade-in"
      data-aos-once="true"
      data-aos-delay="0"
      data-aos-duration="1000"
      className={`bg-protocolNew-base p-4 pb-7 flex gap-4 ${getCardClassNames(
        orientation
      )} ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};

const CardTitle = ({
  children,
  className,
  ...props
}: ComponentProps<"div">) => {
  return (
    <div
      className={`text-base text-protocolNew-neutral leading-5 font-jetbrains ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};

const CardText = ({ children, className, ...props }: ComponentProps<"div">) => {
  return (
    <div
      className={`font-light text-sm text-protocolNew-sub-text leading-4 font-inter ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};

Card.Title = CardTitle;
Card.Text = CardText;

export default Card;

const XIcon = () => {
  return (
    <svg
      viewBox="0 0 18 18"
      className="w-5 h-5 fill-current"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8221 15.5999L10.5451 7.9067L10.5541 7.9139L15.3121 2.3999H13.7221L9.84612 6.8879L6.76812 2.3999H2.59812L7.52472 9.5825L7.52412 9.5819L2.32812 15.5999H3.91812L8.22732 10.6067L11.6521 15.5999H15.8221ZM6.13812 3.5999L13.5421 14.3999H12.2821L4.87212 3.5999H6.13812Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default XIcon;

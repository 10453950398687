import React from "react";

export const Community = () => {
  return (
    <div>
      <svg
        width={345}
        height={326}
        viewBox="0 0 345 326"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* #1 */}
        <path
          opacity="1"
          d="M100.0913 153.435H95.262C96.8441 153.435 98.3856 152.934 99.6651 152.003L106.021 147.381C107.301 146.45 108.842 145.949 110.424 145.949H124.833"
          stroke="#F8F8F8"
          className="forward_line -z-10"
          strokeWidth="1.40365"
        />
        <path
          opacity="0.9"
          d="M97.0913 153.435H95.262C96.8441 153.435 98.3856 152.934 99.6651 152.003L106.021 147.381C107.301 146.45 108.842 145.949 110.424 145.949H124.833"
          stroke="#F8F8F8"
          className="forward_line -z-10"
          strokeWidth="1.40365"
        />
        <path
          opacity="0.8"
          d="M94.0913 153.435H95.262C96.8441 153.435 98.3856 152.934 99.6651 152.003L106.021 147.381C107.301 146.45 108.842 145.949 110.424 145.949H124.833"
          stroke="#F8F8F8"
          className="forward_line -z-10"
          strokeWidth="1.40365"
        />
        <path
          opacity="0.6"
          d="M91.0913 153.435H95.262C96.8441 153.435 98.3856 152.934 99.6651 152.003L106.021 147.381C107.301 146.45 108.842 145.949 110.424 145.949H124.833"
          stroke="#F8F8F8"
          className="forward_line -z-10"
          strokeWidth="1.40365"
        />
        <path
          opacity="0.5"
          d="M88.0913 153.435H95.262C96.8441 153.435 98.3856 152.934 99.6651 152.003L106.021 147.381C107.301 146.45 108.842 145.949 110.424 145.949H124.833"
          stroke="#F8F8F8"
          className="forward_line -z-10"
          strokeWidth="1.40365"
        />
        <path
          opacity="0.3"
          d="M85.0913 153.435H95.262C96.8441 153.435 98.3856 152.934 99.6651 152.003L106.021 147.381C107.301 146.45 108.842 145.949 110.424 145.949H124.833"
          stroke="#F8F8F8"
          className="forward_line -z-10"
          strokeWidth="1.40365"
        />
        <path
          opacity="0.1"
          d="M82.0913 153.435H95.262C96.8441 153.435 98.3856 152.934 99.6651 152.003L106.021 147.381C107.301 146.45 108.842 145.949 110.424 145.949H124.833"
          stroke="#F8F8F8"
          className="forward_line -z-10"
          strokeWidth="1.40365"
        />
        {/* #2 */}
        <path
          opacity="0.2"
          d="M290.492 153.435H249.853C248.271 153.435 246.73 153.936 245.45 154.867L239.094 159.489C237.815 160.42 236.273 160.921 234.691 160.921H220.282"
          stroke="#F8F8F8"
          className="forward_line -z-10"
          strokeWidth="1.40365"
        />
        <path
          opacity="0.3"
          d="M287.492 153.435H249.853C248.271 153.435 246.73 153.936 245.45 154.867L239.094 159.489C237.815 160.42 236.273 160.921 234.691 160.921H220.282"
          stroke="#F8F8F8"
          className="forward_line -z-10"
          strokeWidth="1.40365"
        />
        <path
          opacity="0.4"
          d="M284.492 153.435H249.853C248.271 153.435 246.73 153.936 245.45 154.867L239.094 159.489C237.815 160.42 236.273 160.921 234.691 160.921H220.282"
          stroke="#F8F8F8"
          className="forward_line -z-10"
          strokeWidth="1.40365"
        />
        <path
          opacity="0.6"
          d="M281.492 153.435H249.853C248.271 153.435 246.73 153.936 245.45 154.867L239.094 159.489C237.815 160.42 236.273 160.921 234.691 160.921H220.282"
          stroke="#F8F8F8"
          className="forward_line -z-10"
          strokeWidth="1.40365"
        />
        <path
          opacity="0.7"
          d="M278.492 153.435H249.853C248.271 153.435 246.73 153.936 245.45 154.867L239.094 159.489C237.815 160.42 236.273 160.921 234.691 160.921H220.282"
          stroke="#F8F8F8"
          className="forward_line -z-10"
          strokeWidth="1.40365"
        />
        <path
          opacity="0.9"
          d="M275.492 153.435H249.853C248.271 153.435 246.73 153.936 245.45 154.867L239.094 159.489C237.815 160.42 236.273 160.921 234.691 160.921H220.282"
          stroke="#F8F8F8"
          className="forward_line -z-10"
          strokeWidth="1.40365"
        />
        <path
          opacity="1"
          d="M272.492 153.435H249.853C248.271 153.435 246.73 153.936 245.45 154.867L239.094 159.489C237.815 160.42 236.273 160.921 234.691 160.921H220.282"
          stroke="#F8F8F8"
          className="forward_line -z-10"
          strokeWidth="1.40365"
        />
        <mask
          id="mask0_1560_57643"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x={0}
          y={-139}
          width={345}
          height={345}
        >
          <circle
            cx="172.558"
            cy="33.6571"
            r="172.181"
            fill="url(#paint0_radial_1560_57643)"
          />
        </mask>
        <g clipPath="url(#clip0_1560_57643)">
          <mask
            id="mask1_1560_57643"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x={0}
            y={-19}
            width={345}
            height={345}
          >
            <circle
              cx="172.558"
              cy="153.435"
              r="172.181"
              fill="url(#paint1_radial_1560_57643)"
            />
          </mask>
          <g mask="url(#mask1_1560_57643)">
            <g opacity="0.1">
              <circle
                opacity="0.3"
                cx="31.8037"
                cy="12.6812"
                r="1.48244"
                fill="#F8F8F8"
              />
              <circle cx="50.4449" cy="12.6071" r="1.40832" fill="#F8F8F8" />
              <circle cx="69.5169" cy="12.9638" r="1.29716" fill="#F8F8F8" />
              <circle cx="88.084" cy="12.8156" r="1.14893" fill="#F8F8F8" />
              <circle cx="106.67" cy="12.6859" r="1.01923" fill="#F8F8F8" />
              <circle cx="125.686" cy="12.987" r="0.852481" fill="#F8F8F8" />
              <circle cx="144.235" cy="12.8202" r="0.685728" fill="#F8F8F8" />
              <circle cx="162.802" cy="12.672" r="0.537503" fill="#F8F8F8" />
              <circle cx="181.447" cy="12.6024" r="0.467882" fill="#F8F8F8" />
              <circle cx="200.163" cy="12.6024" r="0.467882" fill="#F8F8F8" />
              <circle cx="218.878" cy="12.6024" r="0.467882" fill="#F8F8F8" />
              <circle cx="237.593" cy="12.6024" r="0.467882" fill="#F8F8F8" />
              <circle cx="256.308" cy="12.6024" r="0.467882" fill="#F8F8F8" />
              <circle cx="275.024" cy="12.6024" r="0.467882" fill="#F8F8F8" />
              <circle cx="293.739" cy="12.6024" r="0.467882" fill="#F8F8F8" />
              <circle cx="312.454" cy="12.6024" r="0.467882" fill="#F8F8F8" />
              <circle
                opacity="0.3"
                cx="31.7481"
                cy="31.3409"
                r="1.42685"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.4"
                cx="50.8571"
                cy="31.7347"
                r="1.35274"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.4"
                cx="69.4428"
                cy="31.605"
                r="1.22304"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.4"
                cx="88.0099"
                cy="31.4568"
                r="1.07482"
                fill="#F8F8F8"
              />
              <circle cx="107.045" cy="31.7764" r="0.926593" fill="#F8F8F8" />
              <circle cx="125.593" cy="31.6097" r="0.75984" fill="#F8F8F8" />
              <circle cx="144.142" cy="31.4429" r="0.593087" fill="#F8F8F8" />
              <circle cx="162.732" cy="31.3177" r="0.467882" fill="#F8F8F8" />
              <circle cx="181.447" cy="31.3177" r="0.467882" fill="#F8F8F8" />
              <circle cx="200.163" cy="31.3177" r="0.467882" fill="#F8F8F8" />
              <circle cx="218.878" cy="31.3177" r="0.467882" fill="#F8F8F8" />
              <circle cx="237.593" cy="31.3177" r="0.467882" fill="#F8F8F8" />
              <circle cx="256.308" cy="31.3177" r="0.467882" fill="#F8F8F8" />
              <circle cx="275.024" cy="31.3177" r="0.467882" fill="#F8F8F8" />
              <circle cx="293.739" cy="31.3177" r="0.467882" fill="#F8F8F8" />
              <circle cx="312.454" cy="31.3177" r="0.467882" fill="#F8F8F8" />
              <circle
                opacity="0.3"
                cx="32.1603"
                cy="50.4684"
                r="1.37127"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.4"
                cx="50.7645"
                cy="50.3573"
                r="1.2601"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.4"
                cx="69.3501"
                cy="50.2276"
                r="1.1304"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.4"
                cx="87.8987"
                cy="50.0608"
                r="0.96365"
                fill="#F8F8F8"
              />
              <circle cx="106.952" cy="50.399" r="0.833953" fill="#F8F8F8" />
              <circle cx="125.519" cy="50.2508" r="0.685728" fill="#F8F8F8" />
              <circle cx="144.068" cy="50.084" r="0.518975" fill="#F8F8F8" />
              <circle cx="162.732" cy="50.0329" r="0.467882" fill="#F8F8F8" />
              <circle cx="181.447" cy="50.0329" r="0.467882" fill="#F8F8F8" />
              <circle cx="200.163" cy="50.0329" r="0.467882" fill="#F8F8F8" />
              <circle cx="218.878" cy="50.0329" r="0.467882" fill="#F8F8F8" />
              <circle cx="237.593" cy="50.0329" r="0.467882" fill="#F8F8F8" />
              <circle cx="256.308" cy="50.0329" r="0.467882" fill="#F8F8F8" />
              <circle cx="275.024" cy="50.0329" r="0.467882" fill="#F8F8F8" />
              <circle cx="293.739" cy="50.0329" r="0.467882" fill="#F8F8F8" />
              <circle cx="312.454" cy="50.0329" r="0.467882" fill="#F8F8F8" />
              <circle
                opacity="0.3"
                cx="32.0677"
                cy="69.0911"
                r="1.27863"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.4"
                cx="50.6719"
                cy="68.98"
                r="1.16746"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.4"
                cx="69.2575"
                cy="68.8503"
                r="1.03776"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.4"
                cx="88.3109"
                cy="69.1885"
                r="0.908065"
                fill="#F8F8F8"
              />
              <circle cx="106.897" cy="69.0588" r="0.778368" fill="#F8F8F8" />
              <circle cx="125.464" cy="68.9105" r="0.630143" fill="#F8F8F8" />
              <circle cx="144.068" cy="68.7994" r="0.518975" fill="#F8F8F8" />
              <circle cx="162.732" cy="68.7483" r="0.467882" fill="#F8F8F8" />
              <circle cx="181.447" cy="68.7483" r="0.467882" fill="#F8F8F8" />
              <circle cx="200.163" cy="68.7483" r="0.467882" fill="#F8F8F8" />
              <circle cx="218.878" cy="68.7483" r="0.467882" fill="#F8F8F8" />
              <circle cx="237.593" cy="68.7483" r="0.467882" fill="#F8F8F8" />
              <circle cx="256.308" cy="68.7483" r="0.467882" fill="#F8F8F8" />
              <circle cx="275.024" cy="68.7483" r="0.467882" fill="#F8F8F8" />
              <circle cx="293.739" cy="68.7483" r="0.467882" fill="#F8F8F8" />
              <circle cx="312.454" cy="68.7483" r="0.467882" fill="#F8F8F8" />
              <circle cx="31.975" cy="87.7137" r="1.18599" fill="#F8F8F8" />
              <circle cx="50.5977" cy="87.6211" r="1.09335" fill="#F8F8F8" />
              <circle cx="69.2019" cy="87.5099" r="0.982178" fill="#F8F8F8" />
              <circle cx="88.2553" cy="87.8481" r="0.852481" fill="#F8F8F8" />
              <circle cx="106.878" cy="87.7554" r="0.75984" fill="#F8F8F8" />
              <circle cx="125.464" cy="87.6257" r="0.630143" fill="#F8F8F8" />
              <circle cx="144.086" cy="87.5331" r="0.537503" fill="#F8F8F8" />
              <circle cx="162.746" cy="87.4775" r="0.481918" fill="#F8F8F8" />
              <circle cx="181.447" cy="87.4635" r="0.467882" fill="#F8F8F8" />
              <circle cx="200.163" cy="87.4635" r="0.467882" fill="#F8F8F8" />
              <circle cx="218.878" cy="87.4635" r="0.467882" fill="#F8F8F8" />
              <circle cx="237.593" cy="87.4635" r="0.467882" fill="#F8F8F8" />
              <circle cx="256.308" cy="87.4635" r="0.467882" fill="#F8F8F8" />
              <circle cx="275.024" cy="87.4635" r="0.467882" fill="#F8F8F8" />
              <circle cx="293.739" cy="87.4635" r="0.467882" fill="#F8F8F8" />
              <circle cx="312.454" cy="87.4635" r="0.467882" fill="#F8F8F8" />
              <circle cx="31.9009" cy="106.355" r="1.11187" fill="#F8F8F8" />
              <circle cx="50.5236" cy="106.262" r="1.01923" fill="#F8F8F8" />
              <circle cx="69.6141" cy="106.638" r="0.926593" fill="#F8F8F8" />
              <circle cx="275.024" cy="106.179" r="0.467882" fill="#F8F8F8" />
              <circle cx="293.739" cy="106.179" r="0.467882" fill="#F8F8F8" />
              <circle cx="312.454" cy="106.179" r="0.467882" fill="#F8F8F8" />
              <circle cx="31.8083" cy="124.977" r="1.01923" fill="#F8F8F8" />
              <circle cx="50.468" cy="124.922" r="0.96365" fill="#F8F8F8" />
              <circle cx="69.577" cy="125.316" r="0.889537" fill="#F8F8F8" />
              <circle cx="275.075" cy="124.945" r="0.518975" fill="#F8F8F8" />
              <circle cx="293.739" cy="124.894" r="0.467882" fill="#F8F8F8" />
              <circle cx="312.454" cy="124.894" r="0.467882" fill="#F8F8F8" />
              <circle cx="31.7527" cy="143.637" r="0.96365" fill="#F8F8F8" />
              <circle cx="50.8993" cy="144.068" r="0.926593" fill="#F8F8F8" />
              <circle cx="69.577" cy="144.031" r="0.889537" fill="#F8F8F8" />
              <circle cx="275.223" cy="143.809" r="0.6672" fill="#F8F8F8" />
              <circle cx="293.864" cy="143.735" r="0.593087" fill="#F8F8F8" />
              <circle cx="312.524" cy="143.679" r="0.537503" fill="#F8F8F8" />
              <circle cx="32.1654" cy="162.765" r="0.908065" fill="#F8F8F8" />
              <circle cx="50.8622" cy="162.746" r="0.889537" fill="#F8F8F8" />
              <circle cx="69.577" cy="162.746" r="0.889537" fill="#F8F8F8" />
              <circle cx="275.371" cy="162.672" r="0.815425" fill="#F8F8F8" />
              <circle cx="294.012" cy="162.598" r="0.741312" fill="#F8F8F8" />
              <circle cx="312.635" cy="162.505" r="0.648672" fill="#F8F8F8" />
              <circle cx="32.1469" cy="181.462" r="0.889537" fill="#F8F8F8" />
              <circle cx="50.8622" cy="181.462" r="0.889537" fill="#F8F8F8" />
              <circle cx="69.5956" cy="181.48" r="0.908065" fill="#F8F8F8" />
              <circle cx="275.033" cy="181.049" r="0.945122" fill="#F8F8F8" />
              <circle cx="294.123" cy="181.424" r="0.852481" fill="#F8F8F8" />
              <circle cx="312.746" cy="181.332" r="0.75984" fill="#F8F8F8" />
              <circle cx="32.1098" cy="200.14" r="0.852481" fill="#F8F8F8" />
              <circle cx="50.8622" cy="200.177" r="0.889537" fill="#F8F8F8" />
              <circle cx="69.6141" cy="200.214" r="0.926593" fill="#F8F8F8" />
              <circle cx="275.107" cy="199.839" r="1.01923" fill="#F8F8F8" />
              <circle cx="293.748" cy="199.764" r="0.945122" fill="#F8F8F8" />
              <circle cx="312.839" cy="200.14" r="0.852481" fill="#F8F8F8" />
              <circle cx="32.1098" cy="218.855" r="0.852481" fill="#F8F8F8" />
              <circle
                opacity="0.5"
                cx="50.8622"
                cy="218.892"
                r="0.889537"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.5"
                cx="69.1834"
                cy="218.498"
                r="0.96365"
                fill="#F8F8F8"
              />
              <circle cx="87.9728" cy="218.572" r="1.03776" fill="#F8F8F8" />
              <circle cx="106.744" cy="218.628" r="1.09335" fill="#F8F8F8" />
              <circle cx="125.515" cy="218.684" r="1.14893" fill="#F8F8F8" />
              <circle cx="219.165" cy="218.758" r="1.22304" fill="#F8F8F8" />
              <circle cx="237.862" cy="218.739" r="1.20452" fill="#F8F8F8" />
              <circle cx="256.54" cy="218.702" r="1.16746" fill="#F8F8F8" />
              <circle cx="275.218" cy="218.665" r="1.1304" fill="#F8F8F8" />
              <circle cx="293.841" cy="218.572" r="1.03776" fill="#F8F8F8" />
              <circle cx="312.464" cy="218.48" r="0.945122" fill="#F8F8F8" />
              <circle cx="32.0913" cy="237.552" r="0.833953" fill="#F8F8F8" />
              <circle
                opacity="0.5"
                cx="50.8807"
                cy="237.626"
                r="0.908065"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.5"
                cx="69.2019"
                cy="237.232"
                r="0.982178"
                fill="#F8F8F8"
              />
              <circle cx="88.0099" cy="237.325" r="1.07482" fill="#F8F8F8" />
              <circle cx="106.799" cy="237.399" r="1.14893" fill="#F8F8F8" />
              <circle cx="125.589" cy="237.473" r="1.22304" fill="#F8F8F8" />
              <circle cx="219.258" cy="237.566" r="1.31568" fill="#F8F8F8" />
              <circle cx="237.954" cy="237.547" r="1.29716" fill="#F8F8F8" />
              <circle cx="256.633" cy="237.51" r="1.2601" fill="#F8F8F8" />
              <circle cx="275.292" cy="237.455" r="1.20452" fill="#F8F8F8" />
              <circle cx="293.934" cy="237.38" r="1.1304" fill="#F8F8F8" />
              <circle cx="312.538" cy="237.269" r="1.01923" fill="#F8F8F8" />
              <circle
                opacity="0.5"
                cx="32.1098"
                cy="256.286"
                r="0.852481"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.5"
                cx="50.8993"
                cy="256.36"
                r="0.926593"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.5"
                cx="69.239"
                cy="255.984"
                r="1.01923"
                fill="#F8F8F8"
              />
              <circle cx="88.0469" cy="256.077" r="1.11187" fill="#F8F8F8" />
              <circle cx="106.836" cy="256.151" r="1.18599" fill="#F8F8F8" />
              <circle cx="125.626" cy="256.225" r="1.2601" fill="#F8F8F8" />
              <circle cx="219.313" cy="256.336" r="1.37127" fill="#F8F8F8" />
              <circle cx="238.01" cy="256.318" r="1.35274" fill="#F8F8F8" />
              <circle
                opacity="0.3"
                cx="256.688"
                cy="256.281"
                r="1.31568"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.3"
                cx="275.367"
                cy="256.244"
                r="1.27863"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.3"
                cx="294.008"
                cy="256.17"
                r="1.20452"
                fill="#F8F8F8"
              />
              <circle cx="312.612" cy="256.059" r="1.09335" fill="#F8F8F8" />
              <circle
                opacity="0.5"
                cx="32.1283"
                cy="275.019"
                r="0.871009"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.5"
                cx="50.4495"
                cy="274.626"
                r="0.945122"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.5"
                cx="69.2575"
                cy="274.718"
                r="1.03776"
                fill="#F8F8F8"
              />
              <circle cx="88.0655" cy="274.811" r="1.1304" fill="#F8F8F8" />
              <circle cx="106.855" cy="274.885" r="1.20452" fill="#F8F8F8" />
              <circle
                opacity="0.3"
                cx="125.644"
                cy="274.959"
                r="1.27863"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.3"
                cx="144.415"
                cy="275.015"
                r="1.33421"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.3"
                cx="163.168"
                cy="275.052"
                r="1.37127"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.3"
                cx="181.902"
                cy="275.07"
                r="1.3898"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.3"
                cx="200.167"
                cy="274.621"
                r="1.40832"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.3"
                cx="218.882"
                cy="274.621"
                r="1.40832"
                fill="#F8F8F8"
              />
              <circle cx="238.047" cy="275.07" r="1.3898" fill="#F8F8F8" />
              <circle
                opacity="0.3"
                cx="256.744"
                cy="275.052"
                r="1.37127"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.3"
                cx="275.422"
                cy="275.015"
                r="1.33421"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.3"
                cx="294.063"
                cy="274.941"
                r="1.2601"
                fill="#F8F8F8"
              />
              <circle cx="312.686" cy="274.848" r="1.16746" fill="#F8F8F8" />
              <circle
                opacity="0.5"
                cx="32.1839"
                cy="293.79"
                r="0.926593"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.5"
                cx="50.468"
                cy="293.359"
                r="0.96365"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.5"
                cx="69.276"
                cy="293.452"
                r="1.05629"
                fill="#F8F8F8"
              />
              <circle cx="88.0655" cy="293.526" r="1.1304" fill="#F8F8F8" />
              <circle cx="106.855" cy="293.6" r="1.20452" fill="#F8F8F8" />
              <circle
                opacity="0.3"
                cx="125.644"
                cy="293.674"
                r="1.27863"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.3"
                cx="144.415"
                cy="293.73"
                r="1.33421"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.3"
                cx="163.168"
                cy="293.767"
                r="1.37127"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.3"
                cx="181.902"
                cy="293.786"
                r="1.3898"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.3"
                cx="200.167"
                cy="293.336"
                r="1.40832"
                fill="#F8F8F8"
              />
              <circle
                opacity="0.3"
                cx="218.882"
                cy="293.336"
                r="1.40832"
                fill="#F8F8F8"
              />
              <circle cx="237.598" cy="293.336" r="1.40832" fill="#F8F8F8" />
              <circle cx="256.313" cy="293.336" r="1.40832" fill="#F8F8F8" />
              <circle cx="275.459" cy="293.767" r="1.37127" fill="#F8F8F8" />
              <circle cx="294.119" cy="293.711" r="1.31568" fill="#F8F8F8" />
              <circle cx="312.742" cy="293.619" r="1.22304" fill="#F8F8F8" />
              <circle cx="200.167" cy="312.052" r="1.40832" fill="#F8F8F8" />
              <circle cx="218.882" cy="312.052" r="1.40832" fill="#F8F8F8" />
              <circle cx="237.616" cy="312.07" r="1.42685" fill="#F8F8F8" />
              <circle cx="256.313" cy="312.052" r="1.40832" fill="#F8F8F8" />
            </g>
          </g>
        </g>
        <mask
          id="mask2_1560_57643"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x={0}
          y={-19}
          width={345}
          height={345}
        >
          <circle
            cx="172.558"
            cy="153.435"
            r="172.181"
            fill="url(#paint2_radial_1560_57643)"
          />
        </mask>
        <g mask="url(#mask2_1560_57643)">
          <path
            opacity="0.2"
            d="M69.6234 215.892V164.196V72.317C69.6234 70.3315 68.8347 68.4274 67.4307 67.0235L53.1007 52.6935C51.6968 51.2896 50.9081 49.3854 50.9081 47.4V-17.8099C50.9081 -21.9444 47.5565 -25.296 43.422 -25.296H-143.263C-147.397 -25.296 -150.749 -21.9444 -150.749 -17.8099V331.698C-150.749 335.832 -147.397 339.184 -143.263 339.184H43.422C47.5565 339.184 50.9081 335.832 50.9081 331.698V241.168C50.9081 239.215 51.6716 237.339 53.0358 235.941L67.4957 221.119C68.8598 219.721 69.6234 217.845 69.6234 215.892Z"
            fill="url(#paint3_linear_1560_57643)"
          />
          <path
            opacity="0.2"
            d="M275.96 215.892V164.196V72.317C275.96 70.3315 276.748 68.4274 278.152 67.0235L292.482 52.6935C293.886 51.2896 294.675 49.3854 294.675 47.4V-17.8099C294.675 -21.9444 298.027 -25.296 302.161 -25.296H488.846C492.98 -25.296 496.332 -21.9444 496.332 -17.8099V331.698C496.332 335.832 492.98 339.184 488.846 339.184H302.161C298.027 339.184 294.675 335.832 294.675 331.698V241.168C294.675 239.215 293.911 237.339 292.547 235.941L278.087 221.119C276.723 219.721 275.96 217.845 275.96 215.892Z"
            fill="url(#paint4_linear_1560_57643)"
          />
          <path
            opacity="0.5"
            d="M50.9082 -25.296V47.4C50.9082 49.3854 51.6969 51.2896 53.1008 52.6935L67.4309 67.0235C68.8348 68.4274 69.6235 70.3315 69.6235 72.317V164.196V215.892C69.6235 217.845 68.8599 219.721 67.4958 221.119L53.0359 235.941C51.6718 237.339 50.9082 239.215 50.9082 241.168V339.184"
            stroke="url(#paint5_linear_1560_57643)"
            strokeWidth="1.40365"
          />
          <path
            opacity="0.5"
            d="M50.9082 -25.296V47.4C50.9082 49.3854 51.6969 51.2896 53.1008 52.6935L67.4309 67.0235C68.8348 68.4274 69.6235 70.3315 69.6235 72.317V164.196V215.892C69.6235 217.845 68.8599 219.721 67.4958 221.119L53.0359 235.941C51.6718 237.339 50.9082 239.215 50.9082 241.168V339.184"
            stroke="url(#paint6_linear_1560_57643)"
            strokeWidth="1.40365"
          />
          <path
            opacity="0.5"
            d="M294.207 -24.8281V47.8679C294.207 49.8533 293.418 51.7575 292.014 53.1614L277.684 67.4914C276.28 68.8953 275.492 70.7994 275.492 72.7849V164.664V216.359C275.492 218.313 276.255 220.189 277.619 221.587L292.079 236.409C293.443 237.807 294.207 239.683 294.207 241.636V339.652"
            stroke="url(#paint7_linear_1560_57643)"
            strokeWidth="1.40365"
          />
        </g>
        <g filter="url(#filter0_bdi_1560_57643)">
          <rect
            x="62.1372"
            y="145.949"
            width="14.9722"
            height="14.9722"
            rx="7.48611"
            fill="#282828"
            fillOpacity="0.7"
            style={{ mixBlendMode: "luminosity" }}
            shapeRendering="crispEdges"
          />
          <rect
            x="62.839"
            y="146.651"
            width="13.5686"
            height="13.5686"
            rx="6.78429"
            stroke="url(#paint8_linear_1560_57643)"
            strokeOpacity="0.25"
            strokeWidth="1.40365"
            shapeRendering="crispEdges"
          />
          <circle
            cx="69.6234"
            cy="153.435"
            r="2.80729"
            transform="rotate(180 69.6234 153.435)"
            fill="#F8F8F8"
          />
        </g>
        <g filter="url(#filter1_bdi_1560_57643)">
          <rect
            x="268.005"
            y="145.949"
            width="14.9722"
            height="14.9722"
            rx="7.48611"
            fill="#282828"
            fillOpacity="0.7"
            style={{ mixBlendMode: "luminosity" }}
            shapeRendering="crispEdges"
          />
          <rect
            x="268.707"
            y="146.651"
            width="13.5686"
            height="13.5686"
            rx="6.78429"
            stroke="url(#paint9_linear_1560_57643)"
            strokeOpacity="0.25"
            strokeWidth="1.40365"
            shapeRendering="crispEdges"
          />
          <circle
            cx="275.492"
            cy="153.435"
            r="2.80729"
            transform="rotate(180 275.492 153.435)"
            fill="#F8F8F8"
          />
        </g>
        <g filter="url(#filter2_bd_1560_57643)">
          <rect
            x="125.77"
            y="209.581"
            width="92.6781"
            height="24.9699"
            rx="12.485"
            fill="#F8F8F8"
            fillOpacity="0.05"
            style={{ mixBlendMode: "luminosity" }}
            shapeRendering="crispEdges"
          />
          <rect
            x="126.471"
            y="210.283"
            width="91.2744"
            height="23.5663"
            rx="11.7831"
            stroke="url(#paint10_linear_1560_57643)"
            strokeOpacity="0.25"
            strokeWidth="1.40365"
            shapeRendering="crispEdges"
          />
          <path
            d="M142.663 225.133L141.578 224.328C141.49 224.266 141.44 224.179 141.428 224.066C141.415 223.942 141.434 223.842 141.484 223.767C141.484 223.754 141.522 223.711 141.596 223.636L142.869 222.513L141.166 222.345C141.116 222.332 141.079 222.326 141.054 222.326C141.041 222.313 141.029 222.307 141.016 222.307C140.929 222.282 140.854 222.22 140.792 222.12C140.742 222.008 140.729 221.895 140.754 221.783L141.185 220.492C141.21 220.392 141.278 220.317 141.39 220.267C141.503 220.217 141.603 220.205 141.69 220.23C141.715 220.23 141.765 220.255 141.84 220.305L143.318 221.165L142.944 219.5C142.931 219.45 142.925 219.419 142.925 219.406C142.925 219.381 142.925 219.363 142.925 219.35C142.925 219.25 142.963 219.157 143.037 219.069C143.125 218.982 143.225 218.938 143.337 218.938H144.703C144.815 218.938 144.909 218.982 144.984 219.069C145.071 219.157 145.115 219.25 145.115 219.35C145.115 219.363 145.115 219.381 145.115 219.406C145.115 219.419 145.109 219.45 145.096 219.5L144.703 221.184L146.2 220.305C146.288 220.255 146.338 220.23 146.35 220.23C146.45 220.205 146.55 220.217 146.649 220.267C146.762 220.317 146.83 220.392 146.855 220.492L147.286 221.783C147.311 221.895 147.298 222.008 147.248 222.12C147.198 222.22 147.124 222.282 147.024 222.307C147.011 222.307 146.993 222.313 146.968 222.326C146.955 222.326 146.924 222.332 146.874 222.345L145.152 222.513L146.444 223.636C146.518 223.711 146.556 223.754 146.556 223.767C146.618 223.842 146.643 223.942 146.631 224.066C146.618 224.179 146.562 224.266 146.462 224.328L145.377 225.133C145.277 225.183 145.171 225.202 145.059 225.189C144.946 225.177 144.859 225.127 144.797 225.04C144.797 225.027 144.79 225.015 144.778 225.002C144.765 224.977 144.747 224.946 144.722 224.909L144.029 223.318L143.337 224.909C143.324 224.946 143.306 224.977 143.281 225.002C143.268 225.015 143.256 225.027 143.243 225.04C143.193 225.127 143.106 225.177 142.981 225.189C142.869 225.202 142.763 225.183 142.663 225.133Z"
            fill="#F8F8F8"
            fillOpacity="0.7"
          />
          <path
            d="M153.898 225.133L152.813 224.328C152.726 224.266 152.676 224.179 152.663 224.066C152.651 223.942 152.67 223.842 152.719 223.767C152.719 223.754 152.757 223.711 152.832 223.636L154.104 222.513L152.401 222.345C152.351 222.332 152.314 222.326 152.289 222.326C152.276 222.313 152.264 222.307 152.252 222.307C152.164 222.282 152.089 222.22 152.027 222.12C151.977 222.008 151.965 221.895 151.99 221.783L152.42 220.492C152.445 220.392 152.514 220.317 152.626 220.267C152.738 220.217 152.838 220.205 152.925 220.23C152.95 220.23 153 220.255 153.075 220.305L154.554 221.165L154.179 219.5C154.167 219.45 154.161 219.419 154.161 219.406C154.161 219.381 154.161 219.363 154.161 219.35C154.161 219.25 154.198 219.157 154.273 219.069C154.36 218.982 154.46 218.938 154.572 218.938H155.938C156.051 218.938 156.144 218.982 156.219 219.069C156.307 219.157 156.35 219.25 156.35 219.35C156.35 219.363 156.35 219.381 156.35 219.406C156.35 219.419 156.344 219.45 156.331 219.5L155.938 221.184L157.436 220.305C157.523 220.255 157.573 220.23 157.585 220.23C157.685 220.205 157.785 220.217 157.885 220.267C157.997 220.317 158.066 220.392 158.091 220.492L158.521 221.783C158.546 221.895 158.534 222.008 158.484 222.12C158.434 222.22 158.359 222.282 158.259 222.307C158.247 222.307 158.228 222.313 158.203 222.326C158.191 222.326 158.159 222.332 158.109 222.345L156.388 222.513L157.679 223.636C157.754 223.711 157.791 223.754 157.791 223.767C157.854 223.842 157.879 223.942 157.866 224.066C157.854 224.179 157.798 224.266 157.698 224.328L156.612 225.133C156.512 225.183 156.406 225.202 156.294 225.189C156.182 225.177 156.094 225.127 156.032 225.04C156.032 225.027 156.026 225.015 156.013 225.002C156.001 224.977 155.982 224.946 155.957 224.909L155.265 223.318L154.572 224.909C154.56 224.946 154.541 224.977 154.516 225.002C154.504 225.015 154.491 225.027 154.479 225.04C154.429 225.127 154.341 225.177 154.217 225.189C154.104 225.202 153.998 225.183 153.898 225.133Z"
            fill="#F8F8F8"
            fillOpacity="0.7"
          />
          <path
            d="M165.134 225.133L164.048 224.328C163.961 224.266 163.911 224.179 163.899 224.066C163.886 223.942 163.905 223.842 163.955 223.767C163.955 223.754 163.992 223.711 164.067 223.636L165.34 222.513L163.637 222.345C163.587 222.332 163.549 222.326 163.524 222.326C163.512 222.313 163.499 222.307 163.487 222.307C163.4 222.282 163.325 222.22 163.262 222.12C163.212 222.008 163.2 221.895 163.225 221.783L163.655 220.492C163.68 220.392 163.749 220.317 163.861 220.267C163.973 220.217 164.073 220.205 164.161 220.23C164.186 220.23 164.236 220.255 164.31 220.305L165.789 221.165L165.415 219.5C165.402 219.45 165.396 219.419 165.396 219.406C165.396 219.381 165.396 219.363 165.396 219.35C165.396 219.25 165.433 219.157 165.508 219.069C165.595 218.982 165.695 218.938 165.808 218.938H167.174C167.286 218.938 167.38 218.982 167.455 219.069C167.542 219.157 167.586 219.25 167.586 219.35C167.586 219.363 167.586 219.381 167.586 219.406C167.586 219.419 167.579 219.45 167.567 219.5L167.174 221.184L168.671 220.305C168.758 220.255 168.808 220.23 168.821 220.23C168.921 220.205 169.02 220.217 169.12 220.267C169.232 220.317 169.301 220.392 169.326 220.492L169.757 221.783C169.781 221.895 169.769 222.008 169.719 222.12C169.669 222.22 169.594 222.282 169.494 222.307C169.482 222.307 169.463 222.313 169.438 222.326C169.426 222.326 169.395 222.332 169.345 222.345L167.623 222.513L168.914 223.636C168.989 223.711 169.027 223.754 169.027 223.767C169.089 223.842 169.114 223.942 169.101 224.066C169.089 224.179 169.033 224.266 168.933 224.328L167.848 225.133C167.748 225.183 167.642 225.202 167.529 225.189C167.417 225.177 167.33 225.127 167.267 225.04C167.267 225.027 167.261 225.015 167.249 225.002C167.236 224.977 167.217 224.946 167.193 224.909L166.5 223.318L165.808 224.909C165.795 224.946 165.776 224.977 165.751 225.002C165.739 225.015 165.726 225.027 165.714 225.04C165.664 225.127 165.577 225.177 165.452 225.189C165.34 225.202 165.234 225.183 165.134 225.133Z"
            fill="#F8F8F8"
            fillOpacity="0.7"
          />
          <path
            d="M176.369 225.133L175.284 224.328C175.196 224.266 175.146 224.179 175.134 224.066C175.122 223.942 175.14 223.842 175.19 223.767C175.19 223.754 175.228 223.711 175.302 223.636L176.575 222.513L174.872 222.345C174.822 222.332 174.785 222.326 174.76 222.326C174.747 222.313 174.735 222.307 174.722 222.307C174.635 222.282 174.56 222.22 174.498 222.12C174.448 222.008 174.435 221.895 174.46 221.783L174.891 220.492C174.916 220.392 174.984 220.317 175.097 220.267C175.209 220.217 175.309 220.205 175.396 220.23C175.421 220.23 175.471 220.255 175.546 220.305L177.024 221.165L176.65 219.5C176.637 219.45 176.631 219.419 176.631 219.406C176.631 219.381 176.631 219.363 176.631 219.35C176.631 219.25 176.669 219.157 176.743 219.069C176.831 218.982 176.931 218.938 177.043 218.938H178.409C178.521 218.938 178.615 218.982 178.69 219.069C178.777 219.157 178.821 219.25 178.821 219.35C178.821 219.363 178.821 219.381 178.821 219.406C178.821 219.419 178.815 219.45 178.802 219.5L178.409 221.184L179.906 220.305C179.994 220.255 180.044 220.23 180.056 220.23C180.156 220.205 180.256 220.217 180.356 220.267C180.468 220.317 180.536 220.392 180.561 220.492L180.992 221.783C181.017 221.895 181.004 222.008 180.954 222.12C180.905 222.22 180.83 222.282 180.73 222.307C180.717 222.307 180.699 222.313 180.674 222.326C180.661 222.326 180.63 222.332 180.58 222.345L178.858 222.513L180.15 223.636C180.225 223.711 180.262 223.754 180.262 223.767C180.324 223.842 180.349 223.942 180.337 224.066C180.324 224.179 180.268 224.266 180.168 224.328L179.083 225.133C178.983 225.183 178.877 225.202 178.765 225.189C178.652 225.177 178.565 225.127 178.503 225.04C178.503 225.027 178.496 225.015 178.484 225.002C178.472 224.977 178.453 224.946 178.428 224.909L177.735 223.318L177.043 224.909C177.03 224.946 177.012 224.977 176.987 225.002C176.974 225.015 176.962 225.027 176.949 225.04C176.899 225.127 176.812 225.177 176.687 225.189C176.575 225.202 176.469 225.183 176.369 225.133Z"
            fill="#F8F8F8"
            fillOpacity="0.7"
          />
          <path
            d="M187.605 225.133L186.519 224.328C186.432 224.266 186.382 224.179 186.369 224.066C186.357 223.942 186.376 223.842 186.425 223.767C186.425 223.754 186.463 223.711 186.538 223.636L187.81 222.513L186.107 222.345C186.057 222.332 186.02 222.326 185.995 222.326C185.983 222.313 185.97 222.307 185.958 222.307C185.87 222.282 185.795 222.22 185.733 222.12C185.683 222.008 185.671 221.895 185.696 221.783L186.126 220.492C186.151 220.392 186.22 220.317 186.332 220.267C186.444 220.217 186.544 220.205 186.631 220.23C186.656 220.23 186.706 220.255 186.781 220.305L188.26 221.165L187.885 219.5C187.873 219.45 187.867 219.419 187.867 219.406C187.867 219.381 187.867 219.363 187.867 219.35C187.867 219.25 187.904 219.157 187.979 219.069C188.066 218.982 188.166 218.938 188.278 218.938H189.645C189.757 218.938 189.85 218.982 189.925 219.069C190.013 219.157 190.056 219.25 190.056 219.35C190.056 219.363 190.056 219.381 190.056 219.406C190.056 219.419 190.05 219.45 190.038 219.5L189.645 221.184L191.142 220.305C191.229 220.255 191.279 220.23 191.291 220.23C191.391 220.205 191.491 220.217 191.591 220.267C191.703 220.317 191.772 220.392 191.797 220.492L192.227 221.783C192.252 221.895 192.24 222.008 192.19 222.12C192.14 222.22 192.065 222.282 191.965 222.307C191.953 222.307 191.934 222.313 191.909 222.326C191.897 222.326 191.865 222.332 191.815 222.345L190.094 222.513L191.385 223.636C191.46 223.711 191.497 223.754 191.497 223.767C191.56 223.842 191.585 223.942 191.572 224.066C191.56 224.179 191.504 224.266 191.404 224.328L190.318 225.133C190.218 225.183 190.112 225.202 190 225.189C189.888 225.177 189.8 225.127 189.738 225.04C189.738 225.027 189.732 225.015 189.719 225.002C189.707 224.977 189.688 224.946 189.663 224.909L188.971 223.318L188.278 224.909C188.266 224.946 188.247 224.977 188.222 225.002C188.21 225.015 188.197 225.027 188.185 225.04C188.135 225.127 188.047 225.177 187.923 225.189C187.81 225.202 187.704 225.183 187.605 225.133Z"
            fill="#F8F8F8"
            fillOpacity="0.7"
          />
          <path
            d="M198.84 225.133L197.755 224.328C197.668 224.266 197.618 224.179 197.605 224.066C197.593 223.942 197.611 223.842 197.661 223.767C197.661 223.754 197.699 223.711 197.774 223.636L199.046 222.513L197.343 222.345C197.293 222.332 197.256 222.326 197.231 222.326C197.218 222.313 197.206 222.307 197.193 222.307C197.106 222.282 197.031 222.22 196.969 222.12C196.919 222.008 196.906 221.895 196.931 221.783L197.362 220.492C197.387 220.392 197.455 220.317 197.568 220.267C197.68 220.217 197.78 220.205 197.867 220.23C197.892 220.23 197.942 220.255 198.017 220.305L199.495 221.165L199.121 219.5C199.109 219.45 199.102 219.419 199.102 219.406C199.102 219.381 199.102 219.363 199.102 219.35C199.102 219.25 199.14 219.157 199.215 219.069C199.302 218.982 199.402 218.938 199.514 218.938H200.88C200.993 218.938 201.086 218.982 201.161 219.069C201.248 219.157 201.292 219.25 201.292 219.35C201.292 219.363 201.292 219.381 201.292 219.406C201.292 219.419 201.286 219.45 201.273 219.5L200.88 221.184L202.378 220.305C202.465 220.255 202.515 220.23 202.527 220.23C202.627 220.205 202.727 220.217 202.827 220.267C202.939 220.317 203.008 220.392 203.033 220.492L203.463 221.783C203.488 221.895 203.476 222.008 203.426 222.12C203.376 222.22 203.301 222.282 203.201 222.307C203.189 222.307 203.17 222.313 203.145 222.326C203.132 222.326 203.101 222.332 203.051 222.345L201.33 222.513L202.621 223.636C202.696 223.711 202.733 223.754 202.733 223.767C202.796 223.842 202.82 223.942 202.808 224.066C202.796 224.179 202.739 224.266 202.64 224.328L201.554 225.133C201.454 225.183 201.348 225.202 201.236 225.189C201.124 225.177 201.036 225.127 200.974 225.04C200.974 225.027 200.968 225.015 200.955 225.002C200.943 224.977 200.924 224.946 200.899 224.909L200.207 223.318L199.514 224.909C199.502 224.946 199.483 224.977 199.458 225.002C199.446 225.015 199.433 225.027 199.421 225.04C199.371 225.127 199.283 225.177 199.159 225.189C199.046 225.202 198.94 225.183 198.84 225.133Z"
            fill="#F8F8F8"
            fillOpacity="0.7"
          />
        </g>
        <g filter="url(#filter3_bi_1560_57643)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M163.71 108.135C162.489 108.135 161.498 109.049 161.498 110.177V114.85C161.498 116.073 161.498 116.684 161.26 117.152C161.051 117.562 160.717 117.896 160.306 118.106C159.839 118.344 159.228 118.344 158.005 118.344H151.721C150.498 118.344 149.887 118.344 149.42 118.106C149.009 117.896 148.675 117.562 148.466 117.152C148.228 116.684 148.228 116.073 148.228 114.85V110.177C148.228 102.284 155.159 95.8854 163.71 95.8854H181.405C189.955 95.8854 196.887 102.284 196.887 110.177V114.85C196.887 116.073 196.887 116.684 196.649 117.152C196.44 117.562 196.106 117.896 195.695 118.106C195.228 118.344 194.617 118.344 193.394 118.344H187.11C185.887 118.344 185.276 118.344 184.809 118.106C184.398 117.896 184.064 117.562 183.854 117.152C183.616 116.684 183.616 116.073 183.616 114.85V110.177C183.616 109.049 182.626 108.135 181.405 108.135H163.71Z"
            fill="#F8F8F8"
            fillOpacity="0.05"
            style={{ mixBlendMode: "luminosity" }}
          />
          <path
            d="M163.71 107.317C162.099 107.317 160.68 108.537 160.68 110.177V114.85C160.68 115.475 160.679 115.895 160.653 116.218C160.627 116.531 160.581 116.681 160.531 116.78C160.4 117.037 160.191 117.245 159.934 117.376C159.836 117.427 159.686 117.472 159.372 117.498C159.049 117.524 158.63 117.525 158.005 117.525H151.721C151.096 117.525 150.677 117.524 150.354 117.498C150.04 117.472 149.89 117.427 149.791 117.376L149.428 118.09L149.791 117.376C149.535 117.245 149.326 117.037 149.195 116.78L148.466 117.152L149.195 116.78C149.145 116.681 149.099 116.531 149.073 116.218C149.047 115.895 149.046 115.475 149.046 114.85V110.177C149.046 102.797 155.549 96.7042 163.71 96.7042H181.405C189.566 96.7042 196.068 102.797 196.068 110.177V114.85C196.068 115.475 196.068 115.895 196.041 116.218C196.016 116.531 195.97 116.681 195.92 116.78C195.789 117.037 195.58 117.245 195.323 117.376C195.224 117.427 195.075 117.472 194.761 117.498C194.438 117.524 194.019 117.525 193.394 117.525H187.11C186.485 117.525 186.066 117.524 185.743 117.498C185.429 117.472 185.279 117.427 185.18 117.376C184.924 117.245 184.715 117.037 184.584 116.78C184.534 116.681 184.488 116.531 184.462 116.218C184.436 115.895 184.435 115.475 184.435 114.85V110.177C184.435 108.537 183.015 107.317 181.405 107.317H163.71Z"
            stroke="url(#paint11_linear_1560_57643)"
            strokeOpacity="0.25"
            strokeWidth="1.63759"
          />
        </g>
        <g filter="url(#filter4_b_1560_57643)">
          <rect
            x="121.09"
            y="116.94"
            width="102.934"
            height="76.7326"
            rx="21.8345"
            fill="#121212"
            fillOpacity="0.5"
            style={{ mixBlendMode: "luminosity" }}
          />
        </g>
        <g clipPath="url(#clip1_1560_57643)">
          <g filter="url(#filter5_bdi_1560_57643)">
            <rect
              x="124.833"
              y="120.683"
              width="95.4479"
              height="69.2465"
              rx="17.4676"
              fill="#F8F8F8"
              fillOpacity="0.05"
              shapeRendering="crispEdges"
            />
            <rect
              x="125.652"
              y="121.502"
              width="93.8103"
              height="67.6089"
              rx="16.6488"
              stroke="url(#paint12_linear_1560_57643)"
              strokeOpacity="0.25"
              strokeWidth="1.63759"
              shapeRendering="crispEdges"
            />
          </g>
          <g opacity="0.5" filter="url(#filter6_f_1560_57643)">
            <rect
              x="170.686"
              y="90.7386"
              width="79.5399"
              height="79.5399"
              rx="39.77"
              fill="#F8F8F8"
              fillOpacity="0.2"
            />
          </g>
        </g>
        <path
          opacity="0.1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M166.319 150.316C167.525 150.316 168.503 149.338 168.503 148.132C168.503 146.926 167.525 145.949 166.319 145.949C165.113 145.949 164.136 146.926 164.136 148.132C164.136 149.338 165.113 150.316 166.319 150.316ZM172.87 150.316C174.075 150.316 175.053 149.338 175.053 148.132C175.053 146.926 174.075 145.949 172.87 145.949C171.664 145.949 170.686 146.926 170.686 148.132C170.686 149.338 171.664 150.316 172.87 150.316ZM181.603 148.132C181.603 149.338 180.626 150.316 179.42 150.316C178.214 150.316 177.236 149.338 177.236 148.132C177.236 146.926 178.214 145.949 179.42 145.949C180.626 145.949 181.603 146.926 181.603 148.132ZM172.87 156.866C174.075 156.866 175.053 155.888 175.053 154.683C175.053 153.477 174.075 152.499 172.87 152.499C171.664 152.499 170.686 153.477 170.686 154.683C170.686 155.888 171.664 156.866 172.87 156.866ZM168.503 154.683C168.503 155.888 167.525 156.866 166.319 156.866C165.113 156.866 164.136 155.888 164.136 154.683C164.136 153.477 165.113 152.499 166.319 152.499C167.525 152.499 168.503 153.477 168.503 154.683ZM179.42 156.866C180.626 156.866 181.603 155.888 181.603 154.683C181.603 153.477 180.626 152.499 179.42 152.499C178.214 152.499 177.236 153.477 177.236 154.683C177.236 155.888 178.214 156.866 179.42 156.866ZM175.053 161.233C175.053 162.439 174.075 163.416 172.87 163.416C171.664 163.416 170.686 162.439 170.686 161.233C170.686 160.027 171.664 159.049 172.87 159.049C174.075 159.049 175.053 160.027 175.053 161.233ZM166.319 163.416C167.525 163.416 168.503 162.439 168.503 161.233C168.503 160.027 167.525 159.049 166.319 159.049C165.113 159.049 164.136 160.027 164.136 161.233C164.136 162.439 165.113 163.416 166.319 163.416ZM181.603 161.233C181.603 162.439 180.626 163.416 179.42 163.416C178.214 163.416 177.236 162.439 177.236 161.233C177.236 160.027 178.214 159.049 179.42 159.049C180.626 159.049 181.603 160.027 181.603 161.233Z"
          fill="#F8F8F8"
        />
        <path
          d="M179.42 150.316C180.626 150.316 181.603 149.338 181.603 148.132C181.603 146.926 180.626 145.949 179.42 145.949C178.214 145.949 177.236 146.926 177.236 148.132C177.236 149.338 178.214 150.316 179.42 150.316Z"
          fill="#F8F8F8"
          fillOpacity="0.5"
        />
        <path
          d="M172.869 156.866C174.075 156.866 175.053 155.888 175.053 154.683C175.053 153.477 174.075 152.499 172.869 152.499C171.664 152.499 170.686 153.477 170.686 154.683C170.686 155.888 171.664 156.866 172.869 156.866Z"
          fill="#F8F8F8"
          fillOpacity="0.5"
        />
        <path
          d="M166.319 156.866C167.525 156.866 168.503 155.888 168.503 154.683C168.503 153.477 167.525 152.499 166.319 152.499C165.113 152.499 164.136 153.477 164.136 154.683C164.136 155.888 165.113 156.866 166.319 156.866Z"
          fill="#F8F8F8"
          fillOpacity="0.5"
        />
        <path
          d="M179.42 156.866C180.626 156.866 181.603 155.888 181.603 154.683C181.603 153.477 180.626 152.499 179.42 152.499C178.214 152.499 177.236 153.477 177.236 154.683C177.236 155.888 178.214 156.866 179.42 156.866Z"
          fill="#F8F8F8"
          fillOpacity="0.5"
        />
        <path
          d="M166.319 163.416C167.525 163.416 168.503 162.439 168.503 161.233C168.503 160.027 167.525 159.049 166.319 159.049C165.113 159.049 164.136 160.027 164.136 161.233C164.136 162.439 165.113 163.416 166.319 163.416Z"
          fill="#F8F8F8"
          fillOpacity="0.5"
        />
        <path
          opacity="0.25"
          d="M70.0913 153.435H95.262C96.8441 153.435 98.3856 152.934 99.6651 152.003L106.021 147.381C107.301 146.45 108.842 145.949 110.424 145.949H124.833"
          stroke="url(#paint13_linear_1560_57643)"
          strokeWidth="1.40365"
        />
        <path
          opacity="0.25"
          d="M275.492 153.435H249.853C248.271 153.435 246.73 153.936 245.45 154.867L239.094 159.489C237.815 160.42 236.273 160.921 234.691 160.921H220.282"
          stroke="url(#paint14_linear_1560_57643)"
          strokeWidth="1.40365"
        />
        <circle
          opacity="0.5"
          cx="0.28339"
          cy="0.28339"
          r="0.28339"
          transform="matrix(0.595006 0.803722 0.803732 -0.594992 255.23 159.151)"
          fill="url(#paint15_linear_1560_57643)"
        />
        <circle
          opacity="0.5"
          cx="0.28339"
          cy="0.28339"
          r="0.28339"
          transform="matrix(0.595006 0.803722 0.803732 -0.594992 271.1 151.783)"
          fill="url(#paint16_linear_1560_57643)"
        />
        <circle
          opacity="0.5"
          cx="0.28339"
          cy="0.28339"
          r="0.28339"
          transform="matrix(0.594999 0.803727 0.803727 -0.594999 226.891 167.086)"
          fill="url(#paint17_linear_1560_57643)"
        />
        <circle
          opacity="0.5"
          cx="0.28339"
          cy="0.28339"
          r="0.28339"
          transform="matrix(0.595006 0.803722 0.803732 -0.594992 248.429 148.382)"
          fill="url(#paint18_linear_1560_57643)"
        />
        <circle
          opacity="0.5"
          cx="0.28339"
          cy="0.28339"
          r="0.28339"
          transform="matrix(0.595006 0.803722 0.803732 -0.594992 275.634 160.285)"
          fill="url(#paint19_linear_1560_57643)"
        />
        <circle
          opacity="0.5"
          cx="1.70034"
          cy="1.70034"
          r="1.70034"
          transform="matrix(4.37114e-08 1 1 -4.37114e-08 234.826 165.048)"
          fill="url(#paint20_linear_1560_57643)"
        />
        <circle
          opacity="0.5"
          cx="1.41695"
          cy="1.41695"
          r="1.41695"
          transform="matrix(4.37109e-08 1 1 -4.37118e-08 264.299 157.113)"
          fill="url(#paint21_linear_1560_57643)"
        />
        <circle
          cx="0.56678"
          cy="0.56678"
          r="0.56678"
          transform="matrix(4.37108e-08 1 1 -4.37119e-08 230.292 144.077)"
          fill="url(#paint22_linear_1560_57643)"
        />
        <circle
          opacity="0.5"
          cx="0.56678"
          cy="0.56678"
          r="0.56678"
          transform="matrix(-0.760019 0.649901 0.649901 0.760019 237.388 157.113)"
          fill="url(#paint23_linear_1560_57643)"
        />
        <circle
          opacity="0.5"
          cx="0.56678"
          cy="0.56678"
          r="0.56678"
          transform="matrix(0.595002 0.803724 0.803729 -0.594995 259.765 152.686)"
          fill="url(#paint24_linear_1560_57643)"
        />
        <circle
          opacity="0.5"
          cx="0.56678"
          cy="0.56678"
          r="0.56678"
          transform="matrix(4.37114e-08 1 1 -4.37114e-08 238.793 145.211)"
          fill="url(#paint25_linear_1560_57643)"
        />
        <circle
          opacity="0.5"
          cx="0.56678"
          cy="0.56678"
          r="0.56678"
          transform="matrix(4.37125e-08 1 1 -4.37103e-08 245.595 163.914)"
          fill="url(#paint26_linear_1560_57643)"
        />
        <circle
          cx="1.5465"
          cy="1.5465"
          r="1.5465"
          transform="matrix(0.82893 0.559352 0.559357 -0.828927 224.96 156.501)"
          fill="white"
          fillOpacity="0.15"
        />
        <circle
          cx="1.13356"
          cy="1.13356"
          r="1.13356"
          transform="matrix(4.37111e-08 1 1 -4.37117e-08 250.696 152.579)"
          fill="url(#paint27_linear_1560_57643)"
        />
        <circle
          opacity="0.5"
          cx="0.28339"
          cy="0.28339"
          r="0.28339"
          transform="matrix(-0.595002 0.803724 -0.803729 -0.594995 98.3066 153.536)"
          fill="url(#paint28_linear_1560_57643)"
        />
        <circle
          opacity="0.5"
          cx="0.28339"
          cy="0.28339"
          r="0.28339"
          transform="matrix(-0.595002 0.803724 -0.803729 -0.594995 82.437 146.168)"
          fill="url(#paint29_linear_1560_57643)"
        />
        <circle
          opacity="0.5"
          cx="0.28339"
          cy="0.28339"
          r="0.28339"
          transform="matrix(-0.594992 0.803732 -0.803722 -0.595006 126.646 161.471)"
          fill="url(#paint30_linear_1560_57643)"
        />
        <circle
          opacity="0.5"
          cx="104.712"
          cy="142.827"
          r="0.28339"
          transform="rotate(126.513 104.712 142.827)"
          fill="url(#paint31_linear_1560_57643)"
        />
        <circle
          opacity="0.5"
          cx="0.28339"
          cy="0.28339"
          r="0.28339"
          transform="matrix(-0.595002 0.803724 -0.803729 -0.594995 77.9023 154.67)"
          fill="url(#paint32_linear_1560_57643)"
        />
        <circle
          opacity="0.5"
          cx="117.01"
          cy="161.134"
          r="1.70034"
          transform="rotate(90 117.01 161.134)"
          fill="url(#paint33_linear_1560_57643)"
        />
        <circle
          opacity="0.5"
          cx="87.8208"
          cy="152.915"
          r="1.41695"
          transform="rotate(90 87.8208 152.915)"
          fill="url(#paint34_linear_1560_57643)"
        />
        <circle
          cx="122.678"
          cy="139.029"
          r="0.56678"
          transform="rotate(90 122.678 139.029)"
          fill="url(#paint35_linear_1560_57643)"
        />
        <circle
          opacity="0.5"
          cx="0.56678"
          cy="0.56678"
          r="0.56678"
          transform="matrix(0.760016 0.649905 -0.649898 0.760022 116.148 151.499)"
          fill="url(#paint36_linear_1560_57643)"
        />
        <circle
          opacity="0.5"
          cx="92.9797"
          cy="147.19"
          r="0.56678"
          transform="rotate(126.513 92.9797 147.19)"
          fill="url(#paint37_linear_1560_57643)"
        />
        <circle
          opacity="0.5"
          cx="114.176"
          cy="140.163"
          r="0.56678"
          transform="rotate(90 114.176 140.163)"
          fill="url(#paint38_linear_1560_57643)"
        />
        <circle
          opacity="0.5"
          cx="107.375"
          cy="158.867"
          r="0.56678"
          transform="rotate(90 107.375 158.867)"
          fill="url(#paint39_linear_1560_57643)"
        />
        <circle
          cx="1.5465"
          cy="1.5465"
          r="1.5465"
          transform="matrix(-0.828929 0.559354 -0.559356 -0.828927 128.577 150.886)"
          fill="white"
          fillOpacity="0.15"
        />
        <circle
          cx="101.707"
          cy="148.098"
          r="1.13356"
          transform="rotate(90 101.707 148.098)"
          fill="url(#paint40_linear_1560_57643)"
        />
        <circle
          opacity="0.75"
          cx="172.558"
          cy="108.518"
          r="61.5064"
          stroke="url(#paint41_angular_1560_57643)"
          strokeWidth="1.40365"
        />
        <circle
          opacity="0.25"
          cx="172.558"
          cy="108.518"
          r="80.4757"
          stroke="url(#paint42_angular_1560_57643)"
          strokeWidth="1.40365"
        />
        <circle
          opacity="0.25"
          cx="172.557"
          cy="108.518"
          r="41.3875"
          stroke="url(#paint43_angular_1560_57643)"
          strokeWidth="1.40365"
        />
        <path
          opacity="0.25"
          d="M-31.439 171.682L31.4315 171.682C33.417 171.682 35.3211 170.894 36.725 169.49L40.2938 165.921C41.6977 164.517 43.6018 163.728 45.5872 163.728L69.6235 163.728"
          stroke="url(#paint44_linear_1560_57643)"
          strokeWidth="1.40365"
        />
        <path
          opacity="0.25"
          d="M-31.439 133.784L31.4315 133.784C33.417 133.784 35.3211 134.573 36.725 135.976L40.2938 139.545C41.6977 140.949 43.6018 141.738 45.5872 141.738L69.6235 141.738"
          stroke="url(#paint45_linear_1560_57643)"
          strokeWidth="1.40365"
        />
        <path
          opacity="0.25"
          d="M376.554 171.682L313.683 171.682C311.698 171.682 309.794 170.894 308.39 169.49L304.821 165.921C303.417 164.517 301.513 163.728 299.528 163.728L275.491 163.728"
          stroke="url(#paint46_linear_1560_57643)"
          strokeWidth="1.40365"
        />
        <path
          opacity="0.25"
          d="M376.554 133.784L313.683 133.784C311.698 133.784 309.794 134.573 308.39 135.976L304.821 139.545C303.417 140.949 301.513 141.738 299.528 141.738L275.491 141.738"
          stroke="url(#paint47_linear_1560_57643)"
          strokeWidth="1.40365"
        />
        <defs>
          <filter
            id="filter0_bdi_1560_57643"
            x="50.1372"
            y="133.949"
            width="38.9722"
            height="66.9722"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation={6} />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_1560_57643"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius={16}
              operator="erode"
              in="SourceAlpha"
              result="effect2_dropShadow_1560_57643"
            />
            <feOffset dy={32} />
            <feGaussianBlur stdDeviation={12} />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
            />
            <feBlend
              mode="multiply"
              in2="effect1_backgroundBlur_1560_57643"
              result="effect2_dropShadow_1560_57643"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect2_dropShadow_1560_57643"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation={4} />
            <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.972549 0 0 0 0 0.972549 0 0 0 0 0.972549 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect3_innerShadow_1560_57643"
            />
          </filter>
          <filter
            id="filter1_bdi_1560_57643"
            x="256.005"
            y="133.949"
            width="38.9722"
            height="66.9722"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation={6} />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_1560_57643"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius={16}
              operator="erode"
              in="SourceAlpha"
              result="effect2_dropShadow_1560_57643"
            />
            <feOffset dy={32} />
            <feGaussianBlur stdDeviation={12} />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
            />
            <feBlend
              mode="multiply"
              in2="effect1_backgroundBlur_1560_57643"
              result="effect2_dropShadow_1560_57643"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect2_dropShadow_1560_57643"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation={4} />
            <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.972549 0 0 0 0 0.972549 0 0 0 0 0.972549 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect3_innerShadow_1560_57643"
            />
          </filter>
          <filter
            id="filter2_bd_1560_57643"
            x="103.311"
            y="187.122"
            width="137.595"
            height="92.345"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="11.2292" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_1560_57643"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="22.4583" />
            <feGaussianBlur stdDeviation="11.2292" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
            />
            <feBlend
              mode="multiply"
              in2="effect1_backgroundBlur_1560_57643"
              result="effect2_dropShadow_1560_57643"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect2_dropShadow_1560_57643"
              result="shape"
            />
          </filter>
          <filter
            id="filter3_bi_1560_57643"
            x="39.0551"
            y="-13.2871"
            width="267.005"
            height="240.803"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="54.5862" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_1560_57643"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_1560_57643"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="4.3669" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.972549 0 0 0 0 0.972549 0 0 0 0 0.972549 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect2_innerShadow_1560_57643"
            />
          </filter>
          <filter
            id="filter4_b_1560_57643"
            x="103.623"
            y="99.4725"
            width="137.869"
            height="111.668"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="8.7338" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_1560_57643"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_1560_57643"
              result="shape"
            />
          </filter>
          <filter
            id="filter5_bdi_1560_57643"
            x="111.733"
            y="107.582"
            width="121.649"
            height="126.016"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.55035" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_1560_57643"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="17.4676"
              operator="erode"
              in="SourceAlpha"
              result="effect2_dropShadow_1560_57643"
            />
            <feOffset dy="34.9352" />
            <feGaussianBlur stdDeviation="13.1007" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
            />
            <feBlend
              mode="multiply"
              in2="effect1_backgroundBlur_1560_57643"
              result="effect2_dropShadow_1560_57643"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect2_dropShadow_1560_57643"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="4.3669" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.972549 0 0 0 0 0.972549 0 0 0 0 0.972549 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect3_innerShadow_1560_57643"
            />
          </filter>
          <filter
            id="filter6_f_1560_57643"
            x="140.742"
            y="60.7942"
            width="139.429"
            height="139.429"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="14.9722"
              result="effect1_foregroundBlur_1560_57643"
            />
          </filter>
          <radialGradient
            id="paint0_radial_1560_57643"
            cx={0}
            cy={0}
            r={1}
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(172.558 33.6571) rotate(90) scale(172.181)"
          >
            <stop stopColor="#D9D9D9" />
            <stop offset="0.566858" stopColor="#737373" stopOpacity={0} />
          </radialGradient>
          <radialGradient
            id="paint1_radial_1560_57643"
            cx={0}
            cy={0}
            r={1}
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(172.558 153.435) rotate(90) scale(172.181)"
          >
            <stop offset="0.433142" stopColor="#737373" stopOpacity={0} />
            <stop offset={1} stopColor="#D9D9D9" />
          </radialGradient>
          <radialGradient
            id="paint2_radial_1560_57643"
            cx={0}
            cy={0}
            r={1}
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(172.558 153.435) rotate(90) scale(172.181)"
          >
            <stop stopColor="#D9D9D9" />
            <stop offset={1} stopColor="#737373" stopOpacity={0} />
          </radialGradient>
          <linearGradient
            id="paint3_linear_1560_57643"
            x1="-143.731"
            y1="62.6658"
            x2="-99.0118"
            y2="206.035"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F8F8F8" stopOpacity="0.8" />
            <stop offset={1} stopColor="#F8F8F8" stopOpacity="0.25" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_1560_57643"
            x1="489.314"
            y1="62.6658"
            x2="444.595"
            y2="206.035"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F8F8F8" stopOpacity="0.8" />
            <stop offset={1} stopColor="#F8F8F8" stopOpacity="0.25" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_1560_57643"
            x1="-23.0171"
            y1="164.196"
            x2="3.05525"
            y2="249.606"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F8F8F8" stopOpacity="0.8" />
            <stop offset="0.45221" stopColor="#F8F8F8" stopOpacity="0.25" />
            <stop offset={1} stopColor="#F8F8F8" stopOpacity="0.8" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_1560_57643"
            x1="-23.0171"
            y1="164.196"
            x2="3.05525"
            y2="249.606"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F8F8F8" stopOpacity="0.8" />
            <stop offset="0.45221" stopColor="#F8F8F8" stopOpacity="0.25" />
            <stop offset={1} stopColor="#F8F8F8" stopOpacity="0.8" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_1560_57643"
            x1="368.132"
            y1="164.664"
            x2="342.06"
            y2="250.073"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F8F8F8" stopOpacity="0.8" />
            <stop offset="0.45221" stopColor="#F8F8F8" stopOpacity="0.25" />
            <stop offset={1} stopColor="#F8F8F8" stopOpacity="0.8" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_1560_57643"
            x1="69.6233"
            y1="145.949"
            x2="76.1365"
            y2="162.39"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0.4" />
            <stop offset="0.4" stopColor="white" stopOpacity="0.01" />
            <stop offset="0.6" stopColor="white" stopOpacity="0.01" />
            <stop offset={1} stopColor="white" stopOpacity="0.1" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_1560_57643"
            x1="275.491"
            y1="145.949"
            x2="282.005"
            y2="162.39"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0.4" />
            <stop offset="0.4" stopColor="white" stopOpacity="0.01" />
            <stop offset="0.6" stopColor="white" stopOpacity="0.01" />
            <stop offset={1} stopColor="white" stopOpacity="0.1" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_1560_57643"
            x1="172.109"
            y1="209.581"
            x2="175.456"
            y2="240.947"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0.4" />
            <stop offset="0.4" stopColor="white" stopOpacity="0.01" />
            <stop offset="0.6" stopColor="white" stopOpacity="0.01" />
            <stop offset={1} stopColor="white" stopOpacity="0.1" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_1560_57643"
            x1="172.557"
            y1="95.8854"
            x2="177.605"
            y2="123.495"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0.4" />
            <stop offset="0.4" stopColor="white" stopOpacity="0.01" />
            <stop offset="0.6" stopColor="white" stopOpacity="0.01" />
            <stop offset={1} stopColor="white" stopOpacity="0.1" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_1560_57643"
            x1="172.557"
            y1="120.683"
            x2="195.912"
            y2="201.945"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0.4" />
            <stop offset="0.4" stopColor="white" stopOpacity="0.01" />
            <stop offset="0.6" stopColor="white" stopOpacity="0.01" />
            <stop offset={1} stopColor="white" stopOpacity="0.1" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_1560_57643"
            x1="70.0913"
            y1="153.903"
            x2="70.1637"
            y2="155.795"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F8F8F8" stopOpacity="0.8" />
            <stop offset="0.54779" stopColor="#F8F8F8" stopOpacity="0.25" />
            <stop offset={1} stopColor="#F8F8F8" stopOpacity="0.8" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_1560_57643"
            x1="275.492"
            y1="152.967"
            x2="275.419"
            y2="151.074"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F8F8F8" stopOpacity="0.8" />
            <stop offset="0.54779" stopColor="#F8F8F8" stopOpacity="0.25" />
            <stop offset={1} stopColor="#F8F8F8" stopOpacity="0.8" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_1560_57643"
            x1="0.472317"
            y1="-0.188927"
            x2="0.141695"
            y2="0.519549"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.173306" stopColor="white" stopOpacity={0} />
            <stop offset={1} stopColor="white" stopOpacity="0.3" />
          </linearGradient>
          <linearGradient
            id="paint16_linear_1560_57643"
            x1="0.472317"
            y1="-0.188927"
            x2="0.141695"
            y2="0.519549"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.173306" stopColor="white" stopOpacity={0} />
            <stop offset={1} stopColor="white" stopOpacity="0.3" />
          </linearGradient>
          <linearGradient
            id="paint17_linear_1560_57643"
            x1="0.472317"
            y1="-0.188927"
            x2="0.141695"
            y2="0.519549"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.173306" stopColor="white" stopOpacity={0} />
            <stop offset={1} stopColor="white" stopOpacity="0.3" />
          </linearGradient>
          <linearGradient
            id="paint18_linear_1560_57643"
            x1="0.472317"
            y1="-0.188927"
            x2="0.141695"
            y2="0.519549"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.173306" stopColor="white" stopOpacity={0} />
            <stop offset={1} stopColor="white" stopOpacity="0.3" />
          </linearGradient>
          <linearGradient
            id="paint19_linear_1560_57643"
            x1="0.472317"
            y1="-0.188927"
            x2="0.141695"
            y2="0.519549"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.173306" stopColor="white" stopOpacity={0} />
            <stop offset={1} stopColor="white" stopOpacity="0.3" />
          </linearGradient>
          <linearGradient
            id="paint20_linear_1560_57643"
            x1="2.8339"
            y1="8.16416e-08"
            x2="-8.72721e-08"
            y2="3.96746"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0.3" />
            <stop offset="0.826694" stopColor="white" stopOpacity={0} />
          </linearGradient>
          <linearGradient
            id="paint21_linear_1560_57643"
            x1="2.36158"
            y1="-0.944634"
            x2="0.708475"
            y2="2.59774"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0.3" />
            <stop offset="0.826694" stopColor="white" stopOpacity={0} />
          </linearGradient>
          <linearGradient
            id="paint22_linear_1560_57643"
            x1="0.944634"
            y1="-0.377853"
            x2="0.28339"
            y2="1.0391"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.173306" stopColor="white" stopOpacity={0} />
            <stop offset={1} stopColor="white" stopOpacity="0.3" />
          </linearGradient>
          <linearGradient
            id="paint23_linear_1560_57643"
            x1="0.944634"
            y1="-0.377853"
            x2="0.28339"
            y2="1.0391"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.173306" stopColor="white" stopOpacity={0} />
            <stop offset={1} stopColor="white" stopOpacity="0.3" />
          </linearGradient>
          <linearGradient
            id="paint24_linear_1560_57643"
            x1="0.944634"
            y1="-0.377853"
            x2="0.28339"
            y2="1.0391"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.173306" stopColor="white" stopOpacity={0} />
            <stop offset={1} stopColor="white" stopOpacity="0.3" />
          </linearGradient>
          <linearGradient
            id="paint25_linear_1560_57643"
            x1="0.944634"
            y1="-0.377853"
            x2="0.28339"
            y2="1.0391"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.173306" stopColor="white" stopOpacity={0} />
            <stop offset={1} stopColor="white" stopOpacity="0.3" />
          </linearGradient>
          <linearGradient
            id="paint26_linear_1560_57643"
            x1="0.944634"
            y1="-0.377853"
            x2="0.28339"
            y2="1.0391"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.173306" stopColor="white" stopOpacity={0} />
            <stop offset={1} stopColor="white" stopOpacity="0.3" />
          </linearGradient>
          <linearGradient
            id="paint27_linear_1560_57643"
            x1="1.88927"
            y1="-0.755707"
            x2="0.56678"
            y2="2.07819"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0.3" />
            <stop offset="0.826694" stopColor="white" stopOpacity={0} />
          </linearGradient>
          <linearGradient
            id="paint28_linear_1560_57643"
            x1="0.472317"
            y1="-0.188927"
            x2="0.141695"
            y2="0.519549"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.173306" stopColor="white" stopOpacity={0} />
            <stop offset={1} stopColor="white" stopOpacity="0.3" />
          </linearGradient>
          <linearGradient
            id="paint29_linear_1560_57643"
            x1="0.472317"
            y1="-0.188927"
            x2="0.141695"
            y2="0.519549"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.173306" stopColor="white" stopOpacity={0} />
            <stop offset={1} stopColor="white" stopOpacity="0.3" />
          </linearGradient>
          <linearGradient
            id="paint30_linear_1560_57643"
            x1="0.472317"
            y1="-0.188927"
            x2="0.141695"
            y2="0.519549"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.173306" stopColor="white" stopOpacity={0} />
            <stop offset={1} stopColor="white" stopOpacity="0.3" />
          </linearGradient>
          <linearGradient
            id="paint31_linear_1560_57643"
            x1="104.9"
            y1="142.355"
            x2="104.57"
            y2="143.063"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.173306" stopColor="white" stopOpacity={0} />
            <stop offset={1} stopColor="white" stopOpacity="0.3" />
          </linearGradient>
          <linearGradient
            id="paint32_linear_1560_57643"
            x1="0.472317"
            y1="-0.188927"
            x2="0.141695"
            y2="0.519549"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.173306" stopColor="white" stopOpacity={0} />
            <stop offset={1} stopColor="white" stopOpacity="0.3" />
          </linearGradient>
          <linearGradient
            id="paint33_linear_1560_57643"
            x1="118.144"
            y1="159.433"
            x2="115.31"
            y2="163.401"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0.3" />
            <stop offset="0.826694" stopColor="white" stopOpacity={0} />
          </linearGradient>
          <linearGradient
            id="paint34_linear_1560_57643"
            x1="88.7655"
            y1="150.554"
            x2="87.1124"
            y2="154.096"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0.3" />
            <stop offset="0.826694" stopColor="white" stopOpacity={0} />
          </linearGradient>
          <linearGradient
            id="paint35_linear_1560_57643"
            x1="123.056"
            y1="138.085"
            x2="122.395"
            y2="139.502"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.173306" stopColor="white" stopOpacity={0} />
            <stop offset={1} stopColor="white" stopOpacity="0.3" />
          </linearGradient>
          <linearGradient
            id="paint36_linear_1560_57643"
            x1="0.944634"
            y1="-0.377853"
            x2="0.28339"
            y2="1.0391"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.173306" stopColor="white" stopOpacity={0} />
            <stop offset={1} stopColor="white" stopOpacity="0.3" />
          </linearGradient>
          <linearGradient
            id="paint37_linear_1560_57643"
            x1="93.3575"
            y1="146.245"
            x2="92.6963"
            y2="147.662"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.173306" stopColor="white" stopOpacity={0} />
            <stop offset={1} stopColor="white" stopOpacity="0.3" />
          </linearGradient>
          <linearGradient
            id="paint38_linear_1560_57643"
            x1="114.554"
            y1="139.218"
            x2="113.893"
            y2="140.635"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.173306" stopColor="white" stopOpacity={0} />
            <stop offset={1} stopColor="white" stopOpacity="0.3" />
          </linearGradient>
          <linearGradient
            id="paint39_linear_1560_57643"
            x1="107.753"
            y1="157.922"
            x2="107.092"
            y2="159.339"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.173306" stopColor="white" stopOpacity={0} />
            <stop offset={1} stopColor="white" stopOpacity="0.3" />
          </linearGradient>
          <linearGradient
            id="paint40_linear_1560_57643"
            x1="102.463"
            y1="146.209"
            x2="101.14"
            y2="149.043"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" stopOpacity="0.3" />
            <stop offset="0.826694" stopColor="white" stopOpacity={0} />
          </linearGradient>
          <radialGradient
            id="paint41_angular_1560_57643"
            cx={0}
            cy={0}
            r={1}
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(172.558 108.518) rotate(90) scale(61.5064)"
          >
            <stop offset="0.310582" stopColor="white" stopOpacity={0} />
            <stop offset="0.504033" stopColor="white" stopOpacity="0.15" />
            <stop offset="0.685479" stopColor="white" stopOpacity={0} />
          </radialGradient>
          <radialGradient
            id="paint42_angular_1560_57643"
            cx={0}
            cy={0}
            r={1}
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(172.558 108.518) rotate(90) scale(80.4757)"
          >
            <stop offset="0.310582" stopColor="white" stopOpacity={0} />
            <stop offset="0.504033" stopColor="white" stopOpacity="0.15" />
            <stop offset="0.685479" stopColor="white" stopOpacity={0} />
          </radialGradient>
          <radialGradient
            id="paint43_angular_1560_57643"
            cx={0}
            cy={0}
            r={1}
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(172.557 108.518) rotate(90) scale(41.3875)"
          >
            <stop offset="0.310582" stopColor="white" stopOpacity={0} />
            <stop offset="0.504033" stopColor="white" stopOpacity="0.15" />
            <stop offset="0.685479" stopColor="white" stopOpacity={0} />
          </radialGradient>
          <linearGradient
            id="paint44_linear_1560_57643"
            x1="-34.2463"
            y1="164.196"
            x2="64.7229"
            y2="159.51"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F8F8F8" stopOpacity={0} />
            <stop offset={1} stopColor="#F8F8F8" stopOpacity="0.8" />
          </linearGradient>
          <linearGradient
            id="paint45_linear_1560_57643"
            x1="-34.2463"
            y1="141.27"
            x2="64.7229"
            y2="145.956"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F8F8F8" stopOpacity={0} />
            <stop offset={1} stopColor="#F8F8F8" stopOpacity="0.8" />
          </linearGradient>
          <linearGradient
            id="paint46_linear_1560_57643"
            x1="379.361"
            y1="164.196"
            x2="280.392"
            y2="159.51"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F8F8F8" stopOpacity={0} />
            <stop offset={1} stopColor="#F8F8F8" stopOpacity="0.8" />
          </linearGradient>
          <linearGradient
            id="paint47_linear_1560_57643"
            x1="379.361"
            y1="141.27"
            x2="280.392"
            y2="145.956"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F8F8F8" stopOpacity={0} />
            <stop offset={1} stopColor="#F8F8F8" stopOpacity="0.8" />
          </linearGradient>
          <clipPath id="clip0_1560_57643">
            <rect
              width="314.417"
              height="314.417"
              fill="white"
              transform="translate(15.3491 -3.77344)"
            />
          </clipPath>
          <clipPath id="clip1_1560_57643">
            <rect
              x="124.833"
              y="120.683"
              width="95.4479"
              height="69.2465"
              rx="17.4676"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

import React, { ComponentProps } from "react";

const FooterButton = ({
  children,
  className,
  ...props
}: ComponentProps<"button">) => {
  return (
    <button
      className={`relative w-full h-full font-sans overflow-hidden text-sm p-2
      border border-protocolNew-footer-btn-border hover:border-protocolNew-base ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default FooterButton;

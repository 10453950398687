const GithubIcon = () => {
  return (
    <svg
      viewBox="0 0 18 18"
      className="w-5 h-5 fill-current"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.9998 1.80005C5.0236 1.80005 1.7998 5.02385 1.7998 9.00005C1.7998 12.3738 4.123 15.1968 7.255 15.978C7.2214 15.8808 7.1998 15.7681 7.1998 15.6283V14.3976C6.9076 14.3976 6.418 14.3976 6.295 14.3976C5.8024 14.3976 5.3644 14.1858 5.152 13.7922C4.9162 13.3548 4.8754 12.6858 4.291 12.2766C4.1176 12.1404 4.2496 11.985 4.4494 12.006C4.8184 12.1104 5.1244 12.3636 5.4124 12.7392C5.6992 13.1154 5.8342 13.2006 6.37 13.2006C6.6298 13.2006 7.0186 13.1856 7.3846 13.128C7.5814 12.6282 7.9216 12.168 8.3374 11.9508C5.9398 11.7042 4.7956 10.5114 4.7956 8.89205C4.7956 8.19485 5.0926 7.52045 5.5972 6.95225C5.4316 6.38825 5.2234 5.23805 5.6608 4.80005C6.7396 4.80005 7.3918 5.49965 7.5484 5.68865C8.086 5.50445 8.6764 5.40005 9.2968 5.40005C9.9184 5.40005 10.5112 5.50445 11.05 5.68985C11.2048 5.50205 11.8576 4.80005 12.9388 4.80005C13.378 5.23865 13.1674 6.39365 13 6.95645C13.5016 7.52345 13.7968 8.19605 13.7968 8.89205C13.7968 10.5102 12.6544 11.7024 10.2604 11.9502C10.9192 12.294 11.3998 13.26 11.3998 13.9878V15.6283C11.3998 15.6906 11.386 15.7357 11.3788 15.7891C14.1844 14.8056 16.1998 12.1416 16.1998 9.00005C16.1998 5.02385 12.976 1.80005 8.9998 1.80005Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default GithubIcon;

import { useRef } from "react";

import Image from "next/image";

import CloseIcon from "../../assets-protocol-v4/images/close.svg";
import FizzImage from "../../assets-protocol-v4/images/fizz/fizz-arrived.webp";
import { trackUserEvent } from "../../utils/analytics";
import { RedirectLinks } from "../../utils/common";
import SecondaryButton from "../SecondaryButton/SecondaryButton";

interface Props {
  close: () => void;
}

const FizzNotifyModal = ({ close }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      close();
    }
  };

  return (
    <div
      className="[animation-delay:3000ms] animate-landing-blur opacity-0 isolate z-[100] fixed inset-0 bg-black bg-opacity-40"
      onClick={onClick}
    >
      <div
        ref={ref}
        className="fixed rounded-lg px-6 md:px-10 pb-8 md:pb-10 flex flex-col gap-8 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border bg-white text-center lg:max-w-[650px] lg:w-[60vw] lg:h-auto max-h-[95dvh] overflow-auto w-[90vw]"
      >
        <span className="z-10 sticky flex top-2 translate-x-4 w-full justify-end md:hidden cursor-pointer">
          <Image
            onClick={() => {
              close();
            }}
            alt=""
            src={CloseIcon}
          />
        </span>
        <div className="mb-[-40%] max-w-[484px]  mx-auto">
          <Image alt="" className="max-w-[484px]  mx-auto" src={FizzImage} />
        </div>
        <div className="flex flex-col gap-5 text-left">
          <div className="flex flex-col gap-6">
            <h2 className="text-protocolNew-base font-extrabold font-title text-[28px] leading-[100%] md:text-3xl text-center uppercase">
              🚀 Fizz Node Has <br className="block md:hidden" /> Arrived!
            </h2>
            <p className="font-inter text-base leading-6 md:leading-8 md:text-lg">
              Join the decentralized compute revolution with Fizz Node, connect
              your GPU and start earning by providing compute power to the
              Spheron Network.
            </p>
          </div>
          <div className="text-base md:text-lg leading-6 md:leading-8 font-inter">
            <p className="font-bold">⚡ Get Started Today:</p>
            <ul className="list-disc ml-6">
              <li>Earn passive rewards by contributing your compute power.</li>
              <li>
                Seamless setup with easy registration and wallet connection.
              </li>
              <li>Exclusive NFT Badge on your first mint!</li>
            </ul>
          </div>
        </div>
        <div className="flex gap-4 md:gap-8 mx-0 md:mx-auto">
          <SecondaryButton
            variant="dark"
            className="py-4 min-w-[45%] md:min-w-[180px]"
            onClick={() => {
              trackUserEvent("Fizz Node Modal Learn More Click");
              window.open("/fizz", "_blank");
            }}
          >
            LEARN MORE
          </SecondaryButton>
          <SecondaryButton
            variant="dark"
            className="py-4 min-w-[45%] md:min-w-[180px]"
            onClick={() => {
              trackUserEvent("Fizz Node Modal Start Earning Click");
              window.open(RedirectLinks.FIZZ_NODE_APP, "_blank");
            }}
          >
            START EARNING
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
};

export default FizzNotifyModal;

import { ComponentProps } from "react";

interface SectionHeadingProps extends ComponentProps<"h2"> {}

const SectionHeading = ({
  className,
  children,
  ...props
}: SectionHeadingProps) => {
  return (
    <h2
      data-aos="fade-up"
      data-aos-once="true"
      data-aos-delay="100"
      data-aos-duration="600"
      className={`text-black text-[clamp(24px,7vw,32px)] leading-[1.3em] lg:text-xxxl lg:leading-[44px] ${className}`}
      {...props}
    >
      {children}
    </h2>
  );
};

export default SectionHeading;

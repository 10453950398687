import React, { useEffect, useRef, useState } from "react";

import Image from "next/image";

import Illustration1 from "../../assets-protocol-v4/images/join-providers-network-section/illustration-1.svg";
import Illustration2 from "../../assets-protocol-v4/images/join-providers-network-section/illustration-2.svg";
import Illustration3 from "../../assets-protocol-v4/images/join-providers-network-section/illustration-3.svg";
import TierBadge1 from "../../assets-protocol-v4/images/join-providers-network-section/tier-badge-1.webp";
import TierBadge10 from "../../assets-protocol-v4/images/join-providers-network-section/tier-badge-10.webp";
import TierBadge2 from "../../assets-protocol-v4/images/join-providers-network-section/tier-badge-2.webp";
import TierBadge3 from "../../assets-protocol-v4/images/join-providers-network-section/tier-badge-3.webp";
import TierBadge4 from "../../assets-protocol-v4/images/join-providers-network-section/tier-badge-4.webp";
import TierBadge5 from "../../assets-protocol-v4/images/join-providers-network-section/tier-badge-5.webp";
import TierBadge6 from "../../assets-protocol-v4/images/join-providers-network-section/tier-badge-6.webp";
import TierBadge7 from "../../assets-protocol-v4/images/join-providers-network-section/tier-badge-7.webp";
import TierBadge8 from "../../assets-protocol-v4/images/join-providers-network-section/tier-badge-8.webp";
import TierBadge9 from "../../assets-protocol-v4/images/join-providers-network-section/tier-badge-9.webp";
import { trackUserEvent } from "../../utils/analytics";
import Card from "../Card";
import SecondaryButton from "../SecondaryButton";

const JoinProvidersNetworkSection = () => {
  const cardContents = [
    {
      id: 0,
      title: "Monitor Your Performance Effortlessly",
      subtitle:
        "Monitor uptime, performance, and system health in one intuitive dashboard. Manage resources with ease.",
    },
    {
      id: 1,
      title: "Explore Our Tiered Network",
      subtitle:
        "Emerge yourself in gamified experience of becoming providers into the network. Watch your providers climbing up in Tiers & picked by end users",
    },
  ];

  const rewardsIcons = [
    { id: 1, icon: Illustration1, classname: "w-[246px] h-[246px]" },
    { id: 2, icon: Illustration2, classname: "w-[176px] h-[176px]" },
    { id: 3, icon: Illustration3, classname: "w-[176px] h-[176px]" },
  ];

  const tieredIcons = [
    { id: 1, icon: TierBadge1 },
    { id: 2, icon: TierBadge2 },
    { id: 3, icon: TierBadge3 },
    { id: 4, icon: TierBadge4 },
    { id: 5, icon: TierBadge5 },
    { id: 6, icon: TierBadge6 },
    { id: 7, icon: TierBadge7 },
    { id: 8, icon: TierBadge8 },
    { id: 9, icon: TierBadge9 },
    { id: 10, icon: TierBadge10 },
  ];

  const [selectedTab, setSelectedTab] = useState<number>(0);

  useEffect(() => {
    if (window.innerWidth >= 1024) {
      const handleScroll = () => {
        const triggerPoint = 5700;
        const currentScrollPosition = window.scrollY;

        if (currentScrollPosition > triggerPoint) {
          setSelectedTab(1);
        } else {
          setSelectedTab(0);
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  return (
    <div className="relative bg-protocolNew-base flex flex-col gap-7 lg:gap-y-20 px-6 lg:p-0">
      <div className="absolute lg:mx-sides hidden lg:flex justify-center border-x lg:w-content h-full z-[1] border-white border-opacity-10">
        <div className="w-1/3 border-x border-white border-opacity-10" />
      </div>
      <div className="absolute lg:hidden flex justify-center border-x w-content-mobile h-full z-[1] border-white border-opacity-10">
        <div className="w-1/3 border-x border-white border-opacity-10" />
      </div>
      <div className="flex flex-col items-center gap-7 lg:gap-8 pt-[72px] lg:pt-[110px] z-[1]">
        <div className="flex flex-col items-center text-center gap-2 font-sans">
          <span
            data-aos="fade-in"
            data-aos-once="true"
            data-aos-delay="0"
            data-aos-duration="600"
            className="text-protocolNew-sub-heading text-[14px] leading-4 tracking-wide uppercase font-normal"
          >
            For Users / Developers
          </span>
          <span
            data-aos="fade-in"
            data-aos-once="true"
            data-aos-delay="200"
            data-aos-duration="600"
            className="mt-2 text-white text-[32px] leading-12 uppercase font-normal"
          >
            Join Spheron&apos;s Tiered Provider Network
          </span>
          <span
            data-aos="fade-in"
            data-aos-once="true"
            data-aos-delay="400"
            data-aos-duration="600"
            className="font-inter text-protocolNew-sub-heading text-base font-normal lg:w-4/5"
          >
            Provider Nodes are the backbone of Spheron&apos;s decentralized
            compute network, offering essential compute resources to users
            across multiple tiers.
          </span>
        </div>
        <div
          data-aos="zoom-in"
          data-aos-once="true"
          data-aos-delay="400"
          data-aos-duration="600"
        >
          <SecondaryButton
            className="px-8 py-4 w-max"
            variant="light"
            onClick={() => {
              trackUserEvent("Earn Rewards Today Click");
              window.open(
                "https://docs.spheron.network/providers/reward-details"
              );
            }}
          >
            EARN REWARDS TODAY
          </SecondaryButton>
        </div>
      </div>
      <div className="h-full flex justify-end z-10 pb-24 lg:pb-[150px] lg:ml-sides">
        <div className="flex flex-col-reverse items-center lg:items-stretch lg:flex-row gap-12 lg:h-[262px] w-full">
          <div className="flex flex-col gap-14 lg:w-1/3">
            {cardContents.map((content) => (
              <div
                data-aos="fade-in"
                data-aos-once="true"
                data-aos-delay="0"
                data-aos-duration="600"
                key={content.id}
                className={`flex flex-row gap-5 items-center ${
                  selectedTab === content.id ? "" : "hidden lg:flex"
                }`}
              >
                <hr
                  className={`bg-white w-1 hidden lg:flex ${
                    selectedTab === content.id
                      ? "animate-expand h-full"
                      : "animate-contract opacity-10 h-4/5"
                  }`}
                />
                <div
                  className="col-span-1 flex flex-col gap-5 cursor-pointer text-center lg:text-left
                  animate-fadeIn lg:animate-none"
                  onClick={() => setSelectedTab(content.id)}
                >
                  <Card.Title className="uppercase text-[17px]">
                    {content.title}
                  </Card.Title>
                  <Card.Text>{content.subtitle}</Card.Text>
                </div>
              </div>
            ))}
          </div>
          {selectedTab === 0 && (
            <div
              data-aos="zoom-in"
              data-aos-once="true"
              data-aos-delay="0"
              data-aos-duration="600"
              className="flex flex-col lg:flex-row lg:w-2/3 items-center justify-center gap-10 h-full animate-fadeIn [animation-duration:400ms]"
            >
              {rewardsIcons.map((icon) => (
                <div key={icon.id} className={icon.classname}>
                  <Image src={icon.icon} alt={`illus-icon-${icon.id}`} />
                </div>
              ))}
            </div>
          )}
          {selectedTab === 1 && (
            <div
              data-aos="zoom-in"
              data-aos-once="true"
              data-aos-delay="0"
              data-aos-duration="600"
              className="lg:w-2/3 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-[22px] animate-fadeIn [animation-duration:400ms]"
            >
              {tieredIcons.map((icon) => (
                <div
                  key={icon.id}
                  className="h-[120px] w-[120px] flex items-center"
                >
                  <Image
                    src={icon.icon}
                    alt={`tier-icon-${icon.id}`}
                    priority
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-row gap-8 items-center justify-center lg:hidden pb-24 z-[1]">
        {cardContents.map((content) => (
          <button
            key={content.id}
            className={`bg-white h-1 w-8 ${
              selectedTab === content.id
                ? "animate-fadeInTab"
                : "opacity-10 animate-fadeOutTab"
            }`}
            onClick={() => setSelectedTab(content.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default JoinProvidersNetworkSection;

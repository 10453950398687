const LinkedinIcon = () => {
  return (
    <svg
      viewBox="0 0 18 18"
      className="w-5 h-5 fill-current"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7599 1.43994H3.23994C2.24634 1.43994 1.43994 2.24634 1.43994 3.23994V14.7599C1.43994 15.7535 2.24634 16.5599 3.23994 16.5599H14.7599C15.7535 16.5599 16.5599 15.7535 16.5599 14.7599V3.23994C16.5599 2.24634 15.7535 1.43994 14.7599 1.43994ZM6.11994 7.19994V14.0399H3.95994V7.19994H6.11994ZM3.95994 5.20914C3.95994 4.70514 4.39194 4.31994 5.03994 4.31994C5.68794 4.31994 6.09474 4.70514 6.11994 5.20914C6.11994 5.71314 5.71674 6.11994 5.03994 6.11994C4.39194 6.11994 3.95994 5.71314 3.95994 5.20914ZM14.0399 14.0399H11.8799C11.8799 14.0399 11.8799 10.7063 11.8799 10.4399C11.8799 9.71994 11.5199 8.99994 10.6199 8.98554H10.5911C9.71994 8.98554 9.35994 9.72714 9.35994 10.4399C9.35994 10.7675 9.35994 14.0399 9.35994 14.0399H7.19994V7.19994H9.35994V8.12154C9.35994 8.12154 10.0547 7.19994 11.4515 7.19994C12.8807 7.19994 14.0399 8.18274 14.0399 10.1735V14.0399Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LinkedinIcon;
